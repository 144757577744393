import React from 'react';
import "./CarruselMarcasStyle.css";
import { marcas } from '../../config/config';
import { Link } from 'react-router-dom';

export const CarruselMarcas = () => {
  const country = process.env.REACT_APP_COUNTRY;
  const marcasPais = marcas[country];

  return (
    
    <div className='slider mt-5'>
        <div className='slide-track'>
          {marcasPais.map((marca, index) => (
            <Link key={index} to={`/marcas/${marca.id}`}>
              <div className="slide">
                <img
                  src={`${process.env.PUBLIC_URL}/${marca.src}`}
                  alt={marca.alt}
                  width="55%"
                  height="auto"
                />
              </div>
            </Link>
          ))}
        </div>
    </div>
  );
};