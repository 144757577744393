import "./Form2style.css"
import React, { useRef, useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { SwalFire } from '../helpers/SwalFire';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import {data} from "../../config/config.js"
import { useTranslation } from "react-i18next";
const apiContacto = process.env.REACT_APP_API_CONTACTO;

export const Form2 = () => {

    const captcha = useRef(null)
    const [loggin, setLoggin] = useState(false);
    const [usuarioValido, setUsuarioValido] = useState(false);
    const navigate = useNavigate();
    const [values, setValues] = useState({
        nombre: '',
        email: '',
        telefono: '',
        interes: '',
        mensaje: ''
    });

    const onChange = () => {
        if (captcha.current.getValue()) {
            console.log('El usuario no es un robot')
            setUsuarioValido(true)
        }
    }

    const swalAlert = (status) => {
        if (status !== 201) {
            SwalFire('Error interno', 'Intente mas tarde. Disculpe las molestias', 'error');
            return false
        }
        SwalFire('Mensaje enviado correctamente', 'Muchas gracias por su mensaje, nos estaremos comunicando a la brevedad', 'success');
        navigate('/');
    }

    const handleInputChange = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })
    };


    const handleSubmit = (e) => {
        axios.post(`${apiContacto}/solicitud-usuario`, {
            values
        })
            .then(function (response) {
                console.log(response);
                navigate('/')
            })
            .catch(function (error) {
                console.log(error);
            });

        if (captcha.current.getValue()) {
            Swal.fire({
                icon: 'success',
                title: 'Mensaje enviado correctamente!',
                text: 'Muchas gracias por su mensaje, nos estaremos comunicando a la brevedad',
            }).then(function () {
                window.location = "index.html";
            });
        } else {
            Swal.fire({
                title: 'Procesando su solicitud',
                html: 'Espere por favor',
                allowEscapeKey: !loggin,
                allowOutsideClick: !loggin,
                didOpen: () => {
                    Swal.showLoading(loggin)
                }
            })
                .then(res => { console.log(res); swalAlert(res.status); setLoggin(false) })
                .catch(err => { console.log(err); swalAlert(err.response.status) })
        }
    }

    <div id="divConfirmRedirectModal" role="dialog" aria-labelledby="pConfirmRedirect" class="modal queueElement" data-bind="visible: showConfirmRedirectDialog()">
        <div id="divConfirmRedirectModal_Content" class="modal-content">
            <div class="modal-body">
                <p id="h2ConfirmRedirect">Su turno comenz&#xF3; a las</p>
                <p id="pConfirmRedirectTime" data-bind="{visible:ticket.windowStartTime()}">
                    <span data-bind="{text:ticket.windowStartTime()}"></span>
                    <span data-bind="{text:ticket.timeZonePostfix()}"></span>
                </p>
                <p id="pConfirmRedirect">Haz click en entrar para acceder al sitio</p>
                <button id="buttonConfirmRedirect" type="button" class="btn" data-bind="click: setActiveClient"><span class="l">Entrar</span><span class="r">&nbsp;</span></button>
            </div>
        </div>
    </div>


    const [t, i18next] = useTranslation("global")


    return (
        <div className="content">
            <img src={"https://raw.githubusercontent.com/RamiroSB/imagenes/main/banner-contactenos1.png"} alt="Imagen contacto" className='imagenesBody' loading="lazy"></img>

            <h1 className='logo'>{t("formulario.contacta-nos1")}<span>{t("formulario.contacta-nos2")}</span></h1>

            <div className="contact-wrapper animated bounceInUp">
                <div className="contact-form">
                    <h3>{t("formulario.contacto")}</h3>
                    <form onSubmit={handleSubmit}>
                        <p>
                            <label>{t("formulario.label-1")}</label>
                            <input onChange={handleInputChange} type="text" name={values.nombre} required />
                        </p>
                        <p>
                            <label>{t("formulario.label-2")}</label>
                            <input onChange={handleInputChange} type="email" name={values.email} required />
                        </p>
                        <p>
                            <label>{t("formulario.label-3")}</label>
                            <input onChange={handleInputChange} type="number" name={values.telefono} required />
                        </p>
                        <p>
                            <label>{t("formulario.label-motivo")}</label>
                            <input onChange={handleInputChange} type="text" name={values.interes} required />
                        </p>
                        <p className="block">
                            <label>{t("formulario.label-4")}</label>
                            <textarea onChange={handleInputChange} name="mensaje" rows="1" value={values.mensaje} required></textarea>
                        </p>

                        <div>
                            <ReCAPTCHA
                                onChange={onChange}
                                ref={captcha}
                                sitekey={data.sitekey}
                            />
                            {!usuarioValido && <button disabled type="submit" className='enviaConsulta' id='botonSubmit'>{t("formulario.btn-enviar")}</button>}
                            {usuarioValido && <button type="submit" className='enviaConsulta' id='botonSubmit'>{t("formulario.btn-enviar")}</button>}
                        </div>
                    </form>
                </div>
                {/* PARAMETRIZADO */}
                <div className="contact-info">
                    <h4>{t("formulario.mas-informacion")}</h4>
                    <ul>
                        <li><i className="fas fa-map-marker-alt"></i> {t("formulario.horario")}: {data.opening_hours}</li>
                        <li><i className="fas fa-phone"></i> {t("formulario.label-3")}: {data.phone} </li>
                        <li><i className="fas fa-envelope-open-text"></i> E-mail: {data.mail}</li>
                    </ul>
                    <p>{t("formulario.direccion")}</p>
                    <p>{data.address}</p>

                </div>

            </div>

        </div>

    )
}
