import React from 'react'
import { Container } from 'react-bootstrap'

export const Legajo = () => {
  return (
    <div>
      <img src={"https://raw.githubusercontent.com/RamiroSB/imagenes/main/rma-banner.png"} alt="Imagen marcas" className='imagenesBody'></img>
      <h2>Legajo Impositivo</h2>
      <Container><img src={"https://raw.githubusercontent.com/RamiroSB/imagenes/main/image001.jpg"} alt="Imagen legajo" className='imagenesBody'></img></Container>
    </div>
  )
}
