import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import '../Carrito/Carrito.css';
import { toast } from 'react-toastify';
import { cartContext } from '../Context/CartContext';
import { useTranslation } from 'react-i18next';

const BtnPostAdd = ({ producto }) => {
    const navigate = useNavigate();
    const { addToCart, cartLocal } = useContext(cartContext);
    const itemInCart = cartLocal.length === 0 ? undefined : cartLocal.find(item => item.Alias === producto.Alias);

    const [t, i18next] = useTranslation("global");

    const onAdd = () => {
        if (!itemInCart || itemInCart.length === 0 || producto.Stock - itemInCart.contador - 1 >= 0) {
            addToCart(producto, 1);
            navigate('/cart');
        } else {
            toast.error(t("producto.stock-insuficiente"), {
                autoClose: 400
            });
        }
    };

    const onAdd2 = () => {
        navigate('/cart');
    };

    return (
        <div className="btnPostAdd">
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '1rem',
                flexWrap: 'wrap'
            }}>
                <Button
                    onClick={onAdd}
                    variant="outline-primary"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.5rem',
                        padding: '0.25rem 0.5rem',
                        fontSize: '0.75rem',
                        justifyContent: 'center',
                        minWidth: '120px',
                    }}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        style={{ width: '14px', height: '14px' }} 
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                        />
                    </svg>

                    {t("producto.agregar")}
                </Button>

                <Button
                    onClick={onAdd2}
                    variant="outline-success"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.5rem',
                        padding: '0.25rem 0.5rem',
                        fontSize: '0.75rem',
                        justifyContent: 'center',
                        minWidth: '120px',
                    }}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        style={{ width: '14px', height: '14px' }}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"
                        />
                    </svg>
                    {t("producto.ir-carrito")}
                </Button>
            </div>
        </div>
    );
};

export default BtnPostAdd;
