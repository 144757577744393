import axios from "axios";
const apiProductos = process.env.REACT_APP_API_PRODUCTOS;

export const getCategorias = async () => {
    try {
        const response = await axios.get(`${apiProductos}/categorias`);
        return response.data;
    } catch (err) {
        console.error('Error al obtener los datos:', err);
        throw err;
    }
};