import React from 'react'
import { Rma2 } from './Rma2'

export const RmaApc = () => {
  return (
    <div>
      <img src={"https://raw.githubusercontent.com/RamiroSB/imagenes/main/rma-banner.png"} alt="Imagen marcas" className='imagenesBody' loading='lazy'></img>
      <Rma2 />
    </div>
  )
}
