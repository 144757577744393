import React from 'react'
import { Rma3 } from './Rma3'


export const RmaGoPro = () => {
  return (
    <div>
      <img src={process.env.PUBLIC_URL + "/assets/Marcas/GOPRO.webp"} alt="Imagen Go Pro RMA" className='imagenesBody' loading='lazy'></img>
      <Rma3 />
    </div>
  )
}


