import './NewLogin.css';

import { useContext, useState } from "react"

import Swal from 'sweetalert2'
import { Unauthorized } from "../helpers/Unauthorized"
import axios from "axios"
import { contexto } from './../Context/Context'
import { authContext } from '../Context/AuthContext';

const urlApiLogin = process.env.REACT_APP_API_LOGIN

export const ChangePassword = () => {

    const { token, desloguearse } = useContext(authContext)
    const [oldPass, setOldPass] = useState({})
    const [newPass, setNewPass] = useState({})
    const [newPass2, setNewPass2] = useState({})
    const [showPassword, setShowPassword] = useState(false);


    const inputChange = (e) => {
        let name = e.target.name
        let value = e.target.value
        setOldPass(values => ({ ...values, [name]: value }))
    }

    const inputChange2 = (e) => {
        let name = e.target.name
        let value = e.target.value
        setNewPass(values => ({ ...values, [name]: value }))
    }

    const inputChange3 = (e) => {
        let name = e.target.name
        let value = e.target.value
        setNewPass2(values => ({ ...values, [name]: value }))
    }

    const changingPassword = (e) => {
        e.preventDefault()
        console.log('👉 ppp ▶', oldPass, newPass, newPass2);

        if (newPass.newPassword !== newPass2.newPassword2) {
            Swal.fire({
                title: 'Contraseña inválida',
                text: 'La nueva contraseña no coincide ',
                icon: 'warning',
                confirmButtonText: 'Entendido'
            })
            return false;
        };

        axios.post(`${urlApiLogin}/change-password`, {
            ActualPass: oldPass.oldPassword,
            NuevaPass: newPass.newPassword
        },
            {
                headers: { 'Authorization': 'Bearer ' + token }
            })
            .then(res => {
                console.log(res);
                Swal.fire({
                    title: 'Contrseña modificada correctamente',
                    text: 'Ha cambiado con éxito su contraseña. Inicie sesión nuevamente.',
                    icon: 'success',
                    confirmButtonText: 'Entendido'
                }).then(function () {
                    desloguearse();
                });
            })
            .catch(
                err => {
                    console.log('res!!!', err.response);
                    if (err.response.status === 400) {
                        //setMsj(err.response.data.message)
                        Swal.fire({
                            title: 'Contraseña inválida',
                            text: err.response.data.message,
                            icon: 'warning',
                            confirmButtonText: 'Entendido'
                        })
                    }
                    else {
                        //setMsj(err.response.data.message);desloguearse();
                        Swal.fire({
                            title: 'Error interno',
                            text: err.response.data.message,
                            icon: 'warning',
                            confirmButtonText: 'Entendido'
                        })
                    }
                }
            )
        setOldPass({})
        setNewPass({})
        setNewPass2({})
    }

    if (token === undefined || token === false) {
        return (
            <Unauthorized />
        )
    }

    // Muestro password 
    const handleShowPassword = () => {
        !showPassword ? setShowPassword(true) : setShowPassword(false);
    }

    return (
        <div className="divChange">
            <h1 className='text-center'>Cambie su contraseña</h1>
            <form onSubmit={changingPassword}>
                <div class="input-container">
                    <input type={!showPassword ? "password" : "text"} name="oldPassword" value={oldPass.oldPassword || ""} onChange={inputChange} placeholder="Contraseña actual" required />
                    <button class="invite-btn" type="button" onClick={handleShowPassword}>
                        {!showPassword ? <i class="fa-solid fa-eye"></i> : <i class="fa-solid fa-eye-slash"></i>}
                    </button>
                </div>

                <div class="input-container">
                    <input type={!showPassword ? "password" : "text"} name="newPassword" value={newPass.newPassword || ""} onChange={inputChange2} placeholder="Nueva contraseña" required />
                    <button class="invite-btn" type="button" onClick={handleShowPassword}>
                        {!showPassword ? <i class="fa-solid fa-eye"></i> : <i class="fa-solid fa-eye-slash"></i>}
                    </button>
                </div>

                <div class="input-container">
                    <input type={!showPassword ? "password" : "text"} name="newPassword2" value={newPass2.newPassword2 || ""} onChange={inputChange3} placeholder="Ingrese nuevamente la nueva contraseña" required />
                    <button class="invite-btn" type="button" onClick={handleShowPassword}>
                        {!showPassword ? <i class="fa-solid fa-eye"></i> : <i class="fa-solid fa-eye-slash"></i>}
                    </button>
                </div>

                <button type="submit">Cambiar contraseña</button>
            </form>
        </div>
    )
}