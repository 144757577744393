import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router';

const apiProductos = process.env.REACT_APP_API_PRODUCTOS;

const useObtenerDestacados = () => {
    const [destacado, setDestacado] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [isNavigating, setIsNavigating] = useState(false);

    useEffect(() => {
        const obtenerDestacados = async () => {
            setLoading(false);
            try {
                const storedDestacado = localStorage.getItem('destacado');
                if (storedDestacado) {
                    setDestacado(JSON.parse(storedDestacado));
                    setTimeout(() => {
                        setLoading(true);
                    }, 1000);
                } else {
                    const res = await axios(`${apiProductos}/ofertas?limit=3&offset=0`);
                    setDestacado(res.data.articulos);
                    setLoading(false);
                    localStorage.setItem('destacado', JSON.stringify(res.data.articulos));
                }
            } catch (err) {
                setError(err);
                setLoading(false);
            }
        };

        obtenerDestacados();

        return () => {
            setDestacado(null);
            setLoading(true);
            setError(null);
        };
    }, []);

    const irADetalles = (param, id) => {
        setIsNavigating(true);
        const id_replaced = id.replace(/ç/g, '');
        navigate(`/detalle?sku=${id_replaced}`, {
            state: {
                param,
                id
            }
        });
    }

    useEffect(() => {
        if (isNavigating) {
            setLoading(true);
            const timeoutId = setTimeout(() => {
                setLoading(false);
                setIsNavigating(false);
            }, 1000);
            return () => clearTimeout(timeoutId);
        }
    }, [isNavigating]);

    return { destacado, loading, error, irADetalles, isNavigating };
};

export default useObtenerDestacados;
