import { Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom'
import {data} from "../../config/config.js"

import './Form2style.css';

export const FormSuccess = ()=>{

    const {id} = useParams ();

    return(
        <div className='divFormSuccess'>
            <h1>Gracias por contactarse con {data.name} </h1>
            <h4>Su solicitud # {id} se ha enviado con éxito</h4>
            <Button variant='secondary'><a href='/'>Volver al inicio</a></Button>
        </div>
    )
}