import axios from 'axios';

const apiProductos = process.env.REACT_APP_API_PRODUCTOS;

// Obtentgo productos por tipeo
export const getProductosPorBusquedaService = async (searchInput, limit = 7, offset = 0) => {
    try {
        const response = await axios.get(`${apiProductos}/info`, {
            params: {
                nombre: searchInput,
                limit,
                offset
            }
        });
        return response.data.articulos;
    } catch (error) {
        console.error('Error fetching products:', error);
        throw error;
    }
};

// Obtengo categorias completas
export const getCategoriasService = async () => {
    try {
        const response = await axios.get(`${apiProductos}/categorias`);
        return response.data;
    } catch (err) {
        console.error('Error al obtener los datos:', err);
        throw err;
    }
};

// Obtengo los productos por SKU
export const getProductosPorSKUService = async (sku) => {
    try {
        const response = await axios.get(`${apiProductos}/busqueda`, {
            params: { sku }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching SKU data:', error);
        throw error;
    }
};