import { createContext } from "react";

import { LocalStorage } from "../Data/LocalStorage";

export const marcasContext = createContext();

const { Provider } = marcasContext;

const MarcasContext = (props) => {
    const [codigoMarca, setCodigoMarca] = LocalStorage("marca");
    const [itemVisto, setItemVisto] = LocalStorage("item", {
        marca: null,
        pagina: 1,
        sku: null,
        min: null,
        max: null,
        sentido: null,
    });

    const contextValue = {
        codigoMarca,
        setCodigoMarca,
        itemVisto,
        setItemVisto,
    };

    return <Provider value={contextValue}>{props.children}</Provider>;
};

export default MarcasContext;
