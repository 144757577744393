import { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from 'axios';

import { Unauthorized } from '../helpers/Unauthorized'
import { DetallesCompraRender } from "./DetallesCompraRender";
import { SwalFire } from "../helpers/SwalFire";
import { authContext } from "../Context/AuthContext";

const apiPedidos = process.env.REACT_APP_API_PEDIDOS
const FormData = require('form-data')

export const DetallesCompra = () => {

    const location = useLocation();
    const [file, setFile] = useState({});
    const props = location;
    const compras = props.state.param;
    const { token, desloguearse } = useContext(authContext);

    const handleFileChange = (e) => {
        setFile({
            ...file,
            [e.target.name]: e.target.value
        })
    };

    const cargarComprobante = (event) => {
        event.preventDefault();
        const data = new FormData();
        const fila = document.getElementById('imagenPago')
        data.append('imagenPago', fila.files[0], fila.files[0].name);

        axios.post(`${apiPedidos}/orden_web/compras/${compras.id_compra}/transferencia`,
            data,
            {
                headers: {
                    'Content-Type': `multipart/form-data`,
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(res => {
                console.log('OK', res);
                SwalFire(`Comprobante enviado correctamente`, '', 'success')
            })
            .catch(err => {
                console.log('NO', err.response);
                if (err.response.status === 401) {
                    SwalFire('Su sesión ha expirado', '', 'error').then(desloguearse)
                }
                else {
                    SwalFire('Actualmente no disponible', 'Lamentamos los inconvenientes, actualmente esta funcion se encuentra deshabilitada', 'warning');
                }
            })
    }

    if (!token) return <Unauthorized />

    return (
        <DetallesCompraRender
            compras={compras}
            cargarComprobante={cargarComprobante}
            handleFileChange={handleFileChange}
            file={file}
        />
    )
};