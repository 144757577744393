import Swal from 'sweetalert2/dist/sweetalert2.js'
import { capacitaciones } from '../../config/config';
import { Card, Row, Col } from 'react-bootstrap'
const country = process.env.REACT_APP_COUNTRY
const capacitacionesPais = capacitaciones[country];


export const Capacitaciones = () => {

  const renderCapacitaciones = () => {
    if (!capacitacionesPais) {
      return <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2em', marginBottom: '2em' }}>
        <br />
        <h1 style={{ textAlign: 'center', fontSize: '2em' }}>No hay capacitaciones disponibles para este país.</h1>
      </div>;
    }

    const handleClick = (imageUrl) => {
      Swal.fire({
        imageUrl: imageUrl,
        imageWidth: 1000,
        imageHeight: 500,
        imageAlt: 'Custom image',
      });
    };


    return capacitacionesPais.map((capacitacion, index) => (
      <Card key={index} className='m-5'>
        <Row className='g-0'>
          <Col md='3'>
            <Card.Img onClick={() => {
              if (capacitacion.imageUrl) {
                handleClick(capacitacion.imageUrl);
              }
            }} className='imagenCardCapacitaciones' src={capacitacion.imagen} alt='imagen capacitacion' />
          </Col>
          <Col md='8'>
            <Card.Body>
              <Card.Title>{capacitacion.titulo}</Card.Title>
              <Card.Text>
                <b>Horario</b> <p className='text-muted'>{capacitacion.horario}</p>
                <b>Fecha</b> <p className='text-muted'>{capacitacion.fecha}</p>
              </Card.Text>
              <a href={capacitacion.inscripcionLink} className='butnCapacitaciones btn btn-primary'>Inscríbete</a>
            </Card.Body>
          </Col>
        </Row>
      </Card>
    ));
  };

  return (
    <div>
      {renderCapacitaciones()}
    </div>
  )
}

