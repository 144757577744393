import axios from "axios";
import { Table, Button } from "react-bootstrap"
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { MdDownloadForOffline } from "react-icons/md";
import './Profile.css'
import { authContext } from "../Context/AuthContext";
import { toast } from "react-toastify";
import { saveAs } from 'file-saver';

const apiPedidos = process.env.REACT_APP_API_PEDIDOS

export const DetallesCompraRender = ({ compras, cargarComprobante, handleFileChange, file }) => {

    const navigate = useNavigate();
    const { token } = useContext(authContext);

    const verPdf = () => {
        axios.get(`${apiPedidos}/orden_web/compra/${compras.id_compra}/transferencia`, { // Ver esto, da error endpoint no encontrado 
            responseType: 'blob',
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then(res => window.open(URL.createObjectURL(res.data)))
            .catch(err => {
                if (err.response && err.response.status === 404) {
                    toast.error('No se ha encontrado el comprobante.', { autoClose: 2000 });
                } else {
                    console.error('Error al obtener el PDF:', err);
                }
            });
    }

    console.log(compras);

    const handleDownloadFactura = (compras) => {
        axios.get(`${apiPedidos}/orden/factura/${compras.Pedido_Nro}/${compras.Pedido_Ext}`, {
            responseType: 'blob',
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then(res => {
                const fileName = `${compras.Factura}.pdf`; // Agregamos la extensión .pdf al nombre del archivo
                saveAs(new Blob([res.data], { type: 'application/pdf' }), fileName); // Usamos FileSaver para descargar
            })
            .catch(err => {
                if (err.response && err.response.status === 404) {
                    toast.error('No se ha encontrado el comprobante.', { autoClose: 2000 });
                } else {
                    console.error('Error al obtener el PDF:', err);
                }
            });
    };

    const fechaUTC = new Date(compras.Fecha);

    // Sumar las horas necesarias para corregir el desfase (en este caso, 3 horas)
    fechaUTC.setHours(fechaUTC.getHours() + 3); // Ajusta el desfase según tu zona horaria

    // Formatear la fecha solo para mostrar día, mes y año, sin la hora
    const opcionesFecha = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    };

    const fechaLocal = fechaUTC.toLocaleDateString('es-ES', opcionesFecha);


    return (
        <div>
            <img src={"https://raw.githubusercontent.com/RamiroSB/imagenes/main/rma-banner.png"} alt="Imagen marcas" className='imagenesBody' loading="lazy"></img>

            <div className="divDetallesCompra">
                <div className="text-center">
                    <h3>Detalle del pedido {compras.Pedido_Nro}/01</h3>
                </div>
                <div className="datosPedido container-fluid">
                    <h4 className="divDetallesCompra">Datos del pedido</h4>
                    <Table className='tablaHistorial'>
                        <thead>
                            <tr>
                                <th>Creada</th>
                                <th>Modificada</th>
                                <th>Pago</th>
                                <th>Precio total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    {/* {`${new Date(compras.Fecha).toLocaleDateString()}
                                (${new Date(compras.Fecha).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })})`} */}
                                    {fechaLocal}
                                </td>
                                <td>
                                    {/* {`${new Date(compras.Fecha).toLocaleDateString()}
                                (${new Date(compras.Fecha).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })})`} */}
                                    {fechaLocal}
                                </td>
                                <td>{(compras.Estado).replace('_', ' ')}</td>
                                <td>U$D {compras.Importe}</td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
                {compras.id_compra && compras.id_compra !== "" && (
                    <>
                        <h4 className="divDetallesCompra">Datos del pago</h4>
                        <Table className="tablaPagos">
                            <thead>
                                <tr>
                                    <th>Comprobante de pago</th>
                                    {compras.Fecha ? <th>Fecha de pago</th> : null}

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="enviarComprobante">
                                        {compras.archivo_transf === 'null' || compras.archivo_transf === null
                                            ?
                                            <form
                                                id="formEnviarComprobante"
                                                enctype="multipart/form-data"
                                                onSubmit={cargarComprobante}
                                            >
                                                <input
                                                    className="form-control" name="imagenPago" type="file" id="imagenPago" value={file.imagenPago} accept=".pdf, .png, .jpg, .jpeg"
                                                    onChange={handleFileChange}
                                                >
                                                </input>
                                                {file.imagenPago
                                                    ? <Button type="submit">Enviar</Button>
                                                    : ""
                                                }
                                            </form>
                                            :
                                            <div id="divVerPdf">
                                                <h6>Transferencia</h6>
                                                {compras.transferencia && compras.transferencia !== "" && compras.transferencia !== null ? (
                                                    <Table className='tablaHistorial transferencia m-0'>
                                                        <thead>
                                                            <tr>
                                                                <th style={{ cursor: 'pointer', textDecoration: 'underline', width: "400px" }}>{compras.transferencia}</th>
                                                                <th className="text-center">
                                                                    <MdDownloadForOffline
                                                                        size={30}
                                                                        style={{ cursor: 'pointer' }}
                                                                        onClick={verPdf}
                                                                    />
                                                                </th>
                                                            </tr>
                                                        </thead>

                                                    </Table>
                                                ) : 'No se realizó transferencia'}
                                            </div>
                                        }
                                    </td>
                                    <td>
                                        {
                                            !compras.Fecha
                                                ? null
                                                : fechaLocal
                                        }
                                    </td>
                                </tr>
                                {compras.Factura !== undefined && compras.Factura !== null && compras.Factura !== "" &&
                                    <tr>
                                        <td className="enviarComprobante">
                                            <div>
                                                <h6>Factura</h6>
                                                <Table className='tablaHistorial factura m-0'>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ cursor: 'pointer', textDecoration: 'underline', width: "400px" }}>{compras.Factura}</th>
                                                            <th className="text-center">
                                                                <MdDownloadForOffline
                                                                    size={30}
                                                                    style={{ cursor: 'pointer' }}
                                                                    onClick={() => handleDownloadFactura(compras)}
                                                                />
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                </Table>
                                            </div>
                                        </td>
                                        <td></td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                    </>
                )}
                <h4 className="divDetallesCompra">Listado de productos</h4>
                <Table className='tablaHistorialItems'>
                    <thead>
                        <tr>
                            <th>SKU</th>
                            <th>Cantidad</th>
                            <th>Precio Unitario sin IVA</th>
                            <th>Precio total sin IVA</th>
                        </tr>
                    </thead>
                    {compras.Items.map(item => {
                        return (
                            <tbody key={item.Alias}>
                                <tr>
                                    <td>{item.Alias}</td>
                                    <td>{item.Cantidad}</td>
                                    <td>{item.Percio}</td>
                                    <td>{item.Percio * item.Cantidad}</td>
                                </tr>
                            </tbody>
                        )
                    })}
                </Table>
                <Button variant="success" onClick={() => navigate('/profile')}>Volver a Historial de Pedidos</Button>
            </div>
        </div>
    )
}