import React from 'react';
import "./FooterMapaStyle.css"
import { useTranslation } from 'react-i18next';

export const FooterMapa = () => {
  const country = process.env.REACT_APP_COUNTRY;
  let mapaSrc = '';

  const [t, i18next] = useTranslation("global")

  if (country === "PY") {
    mapaSrc = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3607.9260527030233!2d-57.61231692376209!3d-25.273072877662184!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x945da70964d86e4b%3A0xa8911e6dcfa807f3!2sSOLUTION%20BOX%20PARAGUAY!5e0!3m2!1ses!2sar!4v1693850791869!5m2!1ses!2sar";
  } else if (country === "UY") {
    mapaSrc = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3272.2019376416815!2d-56.2006447!3d-34.90138150000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x959f802900230e57%3A0x6cbd07be909941a1!2sGalicia%20780%2C%2011100%20Montevideo%2C%20Departamento%20de%20Montevideo%2C%20Uruguay!5e0!3m2!1ses-419!2sar!4v1693488080859!5m2!1ses-419!2sar";
  } else if (country === "HN") {
    mapaSrc = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3869.847660010873!2d-87.18895012398796!3d14.086168686341448!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f6fa384bbc0af51%3A0x31d84fc1f414727c!2sSolution%20Box%20Honduras!5e0!3m2!1ses!2sar!4v1693850839731!5m2!1ses!2sar";
  } else if (country === "USA") {
    mapaSrc = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3592.2772430668197!2d-80.32135962374855!3d25.79442607733142!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9b90a3fbd7171%3A0x4317cc2f4d69057c!2sSolution%20Box%20LLC!5e0!3m2!1ses!2sar!4v1693850901253!5m2!1ses!2sar";
  } else if (country === "SV") {
    mapaSrc = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8972.504778096418!2d-89.24849460578766!3d13.677648682351382!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f632e2a7575622d%3A0xa3e021dfaec6f1cf!2sOfiBodegas%20Urbano!5e0!3m2!1ses-419!2sar!4v1700247128440!5m2!1ses-419!2sar";
  } else if (country === "GT") {
    mapaSrc = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3861.3365104636287!2d-90.49932812398062!3d14.579891085903991!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8589a3515ba370f5%3A0x45cb09ace8ce0e5!2sSolution%20Box%20Guatemala%2C%20S.A.!5e0!3m2!1ses!2sar!4v1693850971034!5m2!1ses!2sar";
  } else if (country === "DO") {
    mapaSrc = "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15138.255337151317!2d-69.9768179!3d18.4581025!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8ea561e7c44ada55%3A0xe82532572d4f9828!2sSolution%20Box%20Dominicana!5e0!3m2!1ses!2sar!4v1694185795899!5m2!1ses!2sar";
  } else if (country === "CL") {
    mapaSrc = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4320.335821529424!2d-70.76576101141443!3d-33.385836415219394!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c1c308bd281f%3A0x68b774264384227c!2sMedtronic%20Lo%20Boza%20107!5e0!3m2!1ses-419!2sar!4v1695653164841!5m2!1ses-419!2sar";
  } else if (country === "PA") {
    mapaSrc = "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15763.985522243527!2d-79.5379943!3d8.9724788!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8faca8c06e1d4fe7%3A0xf5d3361022fd9dc5!2sOfidepositos%20Central!5e0!3m2!1ses!2sar!4v1698256783552!5m2!1ses!2sar";
  } else if (country === "CR") {
    mapaSrc = "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15718.293589014962!2d-84.1060011!3d9.9694062!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8fa0fb33d07251bd%3A0xf07c477334a890d3!2sOfibodegas%20La%20Valencia!5e0!3m2!1ses!2sar!4v1695753246043!5m2!1ses!2sar";
  }
  

  return (
    <div className='mostrarOno'>
      <div className='footer_1'>
        <h2 className='mt-3 mb-3 encontra-oficinas'>{t("home.mapa")}</h2>
        <div className='container'>
          <iframe
            className='mapaSbox'
            src={mapaSrc}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title='mapaSbox'
          />
        </div>
      </div>
    </div>
  )
}