import axios from 'axios';

const urlApiPedidos = process.env.REACT_APP_API_PEDIDOS

export const getTiposDeEntrega = async () => {
    try {
        const response = await axios.get(`${urlApiPedidos}/constantes/tipos_pedido`);
        return response.data;
    } catch (error) {
        console.error("Error al obtener los tipos de entrega:", error);
        throw error;
    }
};
