import React, { useContext, useEffect } from 'react';
import './Checkout.css';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { TbTruckDelivery } from "react-icons/tb";
import { globalContext } from '../Context/GlobalContext';
import { getTiposDeEntrega } from '../../services/CondicionesDePagoYEntrega/getTiposDeEntrega';

export const Facturacion = ({
  user,
  enviarOrden,
  handleActualizarEntrega,
}) => {
  const {
    info,
    tiposDeEntrega,
    setTiposDeEntrega,
    infoInputs,
    handleEntregaChange,
    defaultValue
  } = useContext(globalContext);

  const [t] = useTranslation("global");

  const country = process.env.REACT_APP_COUNTRY;
  let rucPais = country === "PY" ? "RUC" : "DNI/CUIL";

  useEffect(() => {
    infoInputs({ target: { name: 'entrega', value: defaultValue } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  // Obtengo los tipos de entrega
  const fetchTiposDeEntrega = async () => {
    const storedTiposDeEntrega = localStorage.getItem('tiposDeEntrega');
    if (storedTiposDeEntrega) {
      setTiposDeEntrega(JSON.parse(storedTiposDeEntrega));
    } else {
      try {
        const data = await getTiposDeEntrega();
        setTiposDeEntrega(data);
        localStorage.setItem('tiposDeEntrega', JSON.stringify(data));
      } catch (error) {
        console.log(error)
      }
    }
  };


  useEffect(() => {
    fetchTiposDeEntrega();
  }, []);

  useEffect(() => {
    handleActualizarEntrega();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info.entrega, info.pago]);


  return (
    <>
      <div>
        <form onSubmit={enviarOrden}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
            <div style={{ flex: '1', marginRight: '20px' }}>
              <h3 style={{ color: '#475569' }}>{t("facturacion.datos")}</h3>
              <h6 style={{ color: '#9ca3af' }}><b>{t("facturacion.nombre")}:</b> {user.Nombre}</h6>
              <h6 style={{ color: '#9ca3af' }}><b>{t("facturacion.apellido")}: </b>{user.Apellido}</h6>
              <h6 style={{ color: '#9ca3af' }}><b>{t("formulario.label-2")}: </b>{user.Email}</h6>
              <h6 style={{ color: '#9ca3af' }}><b>{rucPais}: </b>{user.Cuit}</h6>
            </div>

            <div style={{ flex: '1' }}>
              <Form.Group controlId='entrega'>
                <Form.Label style={{ fontWeight: 'bold', fontSize: '1.25rem', color: '#475569' }}>
                  <TbTruckDelivery size={'2.5em'} style={{ marginRight: '12px', color: 'red' }} />
                  {t("facturacion.opciones-entrega")}
                </Form.Label>
                <Form.Control
                  as='select'
                  value={info.entrega}
                  name='entrega'
                  onChange={handleEntregaChange}
                >
                  <option value='Retira personalmente'>Seleccione...</option>
                  {tiposDeEntrega.map((entrega) => (
                    <option key={entrega.Codigo} value={entrega.Descripcion}>
                      {entrega.Descripcion}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </div>
          </div>
          <hr />
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
            <div style={{ flex: '1', marginRight: '20px' }}>
              <h3 style={{ color: '#475569' }}>{t("facturacion.direccion-declarada")}</h3>
              <h6 style={{ color: '#9ca3af' }}>{t("facturacion.podra-coordinar")}</h6>
              <br />
              <h6 style={{ color: '#9ca3af' }}><b>{t("formulario.direccion")}: </b>{info.entrega === "ENTREGA" ? user.Domicilio_entrega.Domicilio : user.Domicilio_facturacion.Domicilio}</h6>
              <h6 style={{ color: '#9ca3af' }}><b>{t("facturacion.codigo-postal")}: </b>{info.entrega === "ENTREGA" ? user.Domicilio_entrega.Codigo_postal : user.Domicilio_facturacion.Codigo_postal || 'No informado'}</h6>
              <h6 style={{ color: '#9ca3af' }}><b>{t("facturacion.localidad")}: </b>{info.entrega === "ENTREGA" ? user.Domicilio_entrega.Localidad : user.Domicilio_facturacion.Localidad}</h6>
              <h6 style={{ color: '#9ca3af' }}><b>{t("facturacion.pais")}: </b>{info.entrega === "ENTREGA" ? user.Domicilio_entrega.Pais : user.Domicilio_facturacion.Pais}</h6>
            </div>

            
          </div>
          <hr />
        </form>
      </div>
    </>
  );
};


