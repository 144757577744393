import React, { useContext } from 'react'
import Stepper from '../StepForm/Stepper'
import { globalContext } from '../Context/GlobalContext';

const RegistroUsuario = () => {

  const { colorTheme } = useContext(globalContext)

  return (
    <>
      <div className={`w-screen h-screen flex flex-col items-center justify-content-start py-4 bg-${colorTheme}`}>

        <Stepper />

      </div>


    </>
  );
}

export default RegistroUsuario
