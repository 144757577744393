import React, { useContext } from 'react'
import { Formulario } from '../Formulario/Formulario'
import { globalContext } from '../Context/GlobalContext'

export const TrabajaConNosotros = () => {


  return (
    <div className={``}>
      <img src={"https://raw.githubusercontent.com/RamiroSB/imagenes/main/rma-banner.png"} alt="Imagen marcas" className='imagenesBody' loading='lazy'></img>
      {/*<h2 className={`text-${colorTheme === 'dark' ? 'light' : 'dark'}`}>Sumate a nuestro Staff</h2>
      <hr className={`text-${colorTheme === 'dark' ? 'light' : 'dark'}`} /> */}
      <Formulario />
    </div>
  )
}
