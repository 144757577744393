import { Container } from 'react-bootstrap'
import React from 'react'
import { ForgotPass } from './ForgotPass'

export const PageForgotPass = () => {
  return (
    <div>
      <img src={"https://raw.githubusercontent.com/RamiroSB/imagenes/main/rma-banner.png"} alt="Imagen marcas" className='imagenesBody' loading='lazy'></img>
      <Container>
        <h2>Olvidaste tu password?</h2>
        <ForgotPass />
      </Container>
    </div>
  )
}
