import { useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import { Button } from "react-bootstrap";
import {
  Container,
  Estado,
  Row,
  SmileIcon,
  Card,
  CheckIcon,
  Price,
  ButtonCard,
  Color,
  Beneficios,
  ShildeIcon,
  CopaIcon,
} from "./styles";
import AddItem from '../../Carrito/AddItem'
import { AddAndBuy } from "../../Carrito/AddAndBuy";
import './ProductoVenta.css'
import { cartContext } from '../../Context/CartContext';
import { marcasContext } from '../../Context/MarcasContext';
import { useTranslation } from 'react-i18next';

const ProductoVenta = ({ datos: marca }) => {
  const pais= process.env.REACT_APP_COUNTRY

  const navigate = useNavigate();
  const { cartLocal } = useContext(cartContext);
  const { codigoMarca } = useContext(marcasContext);
  const findBrand = codigoMarca.find(item => item.Descripcion === marca.Marca)

  const itemInCart = cartLocal.length === 0 ? undefined : cartLocal.find(item => item.Alias === marca.Alias);

  const [t, i18next] = useTranslation("global")

  return (
    <Container>
      <Estado>{t("producto.nuevo-destacado")}</Estado>
      <div>
        <Row>
          <h1>{marca.Nombre}</h1>
          <SmileIcon />
        </Row>
        <Price>{marca.Precio ? marca.Moneda_Signo + " " + marca.Precio : <h4>{t("producto.inicie-sesion")}</h4>} </Price>
        <Card>
          {!marca.Precio && <CheckIcon />}
          <div>
            {marca.Precio && <span className="detalleIva"><p><b>* </b>{t("producto.iva-no-incluido")} {pais==="PA"? "ITBMS":"IVA"}</p></span>}
            <span className="title">{marca.Marca} </span>
            <span className="detalle"><p><b>{t("rma.garantia")}: </b> {t("producto.este-producto-garantia")} {marca.Garantia_meses} {t("rma.meses")}</p></span>
            <span className="more"><p><b>Stock: </b> {marca.Precio === undefined ? " " : marca.Stock + " unidades"}</p></span>
            {/* <a href="/" className="more"><p><b>Stock:</b> {marca.Stock > 0 ? marca.Stock + " unidades ": "No hay stock disponible del producto"}</p></a> */}
          </div>
        </Card>
        <Color>
          <p><b>SKU:</b> {marca.Alias}</p>
        </Color>
        <Color>
          <p><b>PM:</b> {marca.Produc_manager != null ? marca.Produc_manager : "Consulte al staff"}</p>
        </Color>

        <ButtonCard id="buttonCard">
          {marca.Precio === undefined
            ? <Button variant="dark" onClick={() => navigate('/login')}>{t("login.iniciar-sesion")}</Button>
            : itemInCart
              ? ""
              : <AddAndBuy producto={marca} />
          }
          {marca.Precio === undefined ? '' : <AddItem producto={marca} />}
          {itemInCart ? <Button variant='secondary' className='mt-2' onClick={() => navigate('/marcas/' + findBrand.Codigo)}>{t("producto.seguir-comprando")} {marca.Marca}</Button> : ""}
        </ButtonCard>
        <Beneficios>
          <li>
            <ShildeIcon />
            <p>
              {t("producto.compra")} {" "}
              <span>
                {t("producto.manera-segura")}
              </span>
            </p>
          </li>
          <li>
            <CopaIcon />
            <p>
              Solution Box {" "}
              <span>{t("producto.productos-calidad")}.</span>
            </p>
          </li>
        </Beneficios>
      </div>

    </Container>
  );
};

export default ProductoVenta;