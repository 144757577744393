import './CardMarcas.css';
import { Button, Card, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from 'react';
import AddItem from "../Carrito/AddItem";
import { globalContext } from '../Context/GlobalContext';
import { useTranslation } from 'react-i18next';

export default function CardMarcas(props) {
	const pais = process.env.REACT_APP_COUNTRY


	const navigate = useNavigate()
	const [isMobile, setIsMobile] = useState(false);
	//const articulosXMarca = props.articulo;
	const articulosXMarca = props.articulosXMarca
	const { grid, handleDisplayList } = useContext(globalContext);
	console.log(articulosXMarca, "articulos por marca")

	const irADetalles = (param, id) => {
		const id_replaced = id.replace(/ç/g, '');
		navigate(`/detalle?sku=${id_replaced}`, {
			state: {
				param,
				id
			}
		});
	}

	// Valido el tamanio de la screen
	const handleResize = () => {
		if (window.innerWidth < 670) {
			setIsMobile(true)
		} else {
			setIsMobile(false)
		}
	}

	useEffect(() => {
		window.addEventListener("resize", handleResize)
	})

	let img = process.env.PUBLIC_URL + "/assets/sinimagen.gif";

	const [t, i18next] = useTranslation("global")

	console.log(articulosXMarca, "display list")

	return (
		<>
			<div style={{ display: "flex", justifyContent: "center" }}>
				<Row>
					{articulosXMarca.map((articulo, idx) => (

						<Col key={idx} style={{ display: "flex", justifyContent: "center" }}>
							<div className='cards-cont' style={{ boxShadow: "0 0 20px #00000034", display: "flex" }}>
								<Card style={{ width: "16rem", border: "none", backgroundColor: "#fff" }}>
									<div style={{ objectFit: "cover", height: "275px", display: "flex", alignItems: "center", justifyContent: "center" }}>
										<Card.Img
											variant="top"
											src={
												articulo.Imagenes
													? process.env.PUBLIC_URL + `/articulos/thumbs/${encodeURIComponent(articulo.Imagenes?.split(',')[0])}`
													: img
											}
											style={{ maxWidth: "fit-content" }}
											alt={'solution box'}
										/>
									</div>
									<Card.Body>
										<Card.Title className='nombre-producto-card' onClick={() => irADetalles(articulo, articulo.Alias)}>{articulo.Nombre}</Card.Title>
										<Card.Text className='textoSKU' style={{ textAlign: 'center' }}><b>SKU:</b> {articulo.Alias}</Card.Text>
										<div className='flexPrecioStock'>
											<Card.Text className='m-0'>
												{articulo.Precio === undefined ? '' : 'U$D: ' + articulo.Precio}
											</Card.Text>
											<Card.Text>
												{articulo.Precio === undefined ? '' : 'Stock: ' + articulo.Stock}
											</Card.Text>

										</div>
										{pais === "PA" ? (
											<p className='precios-sin-iva-card'>
												{articulo.Precio === undefined ? '' : '* Los precios no incluyen ITBMS'}
											</p>
										) : pais === "USA" ? (
											null
										) : (
											<p className='precios-sin-iva-card'>
												{articulo.Precio === undefined ? '' : '* Los precios no incluyen IVA'}
											</p>
										)}

										{/*<p className='precios-sin-iva-card' >{articulo.Precio === undefined ? '' : `* Los precios no incluyen IVA`}</p>*/}										<div className={`logged-buttons ${grid && 'flexGrid'}`}>
											<Button
												style={{
													display: 'flex',
													alignItems: 'center',
													gap: '0.5rem',
													padding: '0.25rem 0.5rem',
													fontSize: '0.75rem',
													justifyContent: 'center',
													minWidth: '120px',
												}}
												variant="outline-danger"
												onClick={() => {
													irADetalles(articulo, articulo.Alias);
													handleDisplayList();
												}}
											>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													fill="none"
													viewBox="0 0 24 24"
													strokeWidth={1.5}
													stroke="currentColor"
													style={{ width: '14px', height: '14px' }} 
												>
													<path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
													<path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
												</svg>

												{t("producto.ver-producto")}
											</Button>


											<br />
											{articulo.Precio !== undefined
												&&
												<div className="ml-0">
													<AddItem producto={articulo} />
												</div>
											}
										</div>
									</Card.Body>
								</Card>
							</div>
						</Col>
					))}
				</Row>
			</div>
		</>
	);
}
