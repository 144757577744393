import axios from 'axios';

const urlApiPedidos = process.env.REACT_APP_API_PEDIDOS

export const getCondicionesDePago = async () => {
  try {
    const response = await axios.get(`${urlApiPedidos}/constantes/condiciones_pago`);
    return response.data;
  } catch (error) {
    console.error("Error al obtener las condiciones de pago:", error);
    throw error;
  }
};
