import './PdfViewer.css';

const PdfViewer = () => {
  const country = process.env.REACT_APP_COUNTRY;

  let pdfPath = '';

  if (country === "PY") {
    pdfPath = "/pdf/termsPY.pdf";
  } else if (country === "UY") {
    pdfPath = "/pdf/termsUY.pdf";
  } else if (country === "HN") {
    pdfPath = "/pdf/termsHN.pdf";
  } else if (country === "USA") {
    pdfPath = "/pdf/termsLLC.pdf";
  } else if (country === "SV") {
    pdfPath = "/pdf/termsSV.pdf";
  } else if (country === "GT") {
    pdfPath = "/pdf/termsGT.pdf";
  } else if (country === "DO") {
    pdfPath = "/pdf/termsDO.pdf";
  } else if (country === "CR") {
    pdfPath = "/pdf/termsCR.pdf";
  } else if (country === "CL") {
    pdfPath = "/pdf/termsCL.pdf";
  } else if (country === "PA") {
    pdfPath = "/pdf/termsPA.pdf";
  }

  return (
    <div>
      <object data={pdfPath} type="application/pdf">
        <p>Este documento está en formato PDF y puede ser descargado.</p>
      </object>

    </div>
  );
}

export default PdfViewer;

