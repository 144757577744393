import axios from "axios";

export const actualizarTiposDeEntrega = async (info, tiposDeEntrega, precompra, setPrecompra, token, user, setActualizando) => {
    if (setActualizando) setActualizando(true);

    let entregaSeleccionada;

    if (info.entrega) {
        entregaSeleccionada = tiposDeEntrega.find(entrega => entrega.Descripcion === info.entrega) || user.Tipo_entrega;
    }

    const precompraActualizada = { ...precompra };

    if (entregaSeleccionada) {
        precompraActualizada.tipo_entrega = { Codigo: entregaSeleccionada.Codigo, Descripcion: entregaSeleccionada.Descripcion };
    }

    if (info.entrega === "ENTREGA") {
        if (user && user.Domicilio_entrega) {
            precompraActualizada.Direccion_Entrega = {
                Domicilio: user.Domicilio_entrega.Domicilio,
                Direccion: user.Domicilio_entrega.Domicilio,
                Localidad: user.Domicilio_entrega.Localidad,
                codigoPostal: user.Domicilio_entrega.Codigo_postal,
                Codigo_Postal: user.Domicilio_entrega.Codigo_postal,
                Codigo_Prov: user.Domicilio_entrega.Codigo_Prov,
                Pais: user.Domicilio_entrega.Pais
            };
        } else {
            console.warn('El usuario o su domicilio de entrega no están definidos.');
        }
    }

    try {
        const response = await axios.post(
            `${process.env.REACT_APP_API_PEDIDOS}/proforma`,
            { precompra: precompraActualizada },
            { headers: { 'Authorization': 'Bearer ' + token } }
        );
        setPrecompra(response.data);
        console.log("Respuesta del post a proforma:", response.data);
        localStorage.setItem('precompra', JSON.stringify(response.data));
    } catch (error) {
        console.error('Error al actualizar precompra:', error);
    }

    if (setActualizando) setActualizando(false);
};
