import './Carrito.css'
import { Link } from 'react-router-dom'
import { useContext } from 'react'
import { cartContext } from '../Context/CartContext'

const CartWidget = () => {

  const { cartLocal } = useContext(cartContext)

  const totalUnidades = !cartLocal ? "" : cartLocal.reduce((total, producto) => total + producto.contador, 0)

  return (
    <div>
      {!totalUnidades
        ? ""
        :
        <Link to='/cart'>
          <span className='contadorCart'>
            {totalUnidades}
          </span>
          <img src="/Carrito/carrito.png" alt="carrito" className="cartWidget" loading='lazy' />
        </Link>}
    </div>
  )
}

export default CartWidget