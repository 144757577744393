import axios from "axios";

export const actualizarCondicionesDePago = async (info, condPago, precompra, setPrecompra, token, user, setActualizando) => {
    if (setActualizando) setActualizando(true);

    let pagoSeleccionado;

    if (info.pago) {
        pagoSeleccionado = condPago.find(condicion => condicion.Codigo === info.pago) || user.Condicion_Pago;
    }

    const precompraActualizada = { ...precompra };

    if (pagoSeleccionado) {
        precompraActualizada.cond_pago = { Codigo: pagoSeleccionado.Codigo, Descripcion: pagoSeleccionado.Descripcion };
    }

    try {
        const response = await axios.post(
            `${process.env.REACT_APP_API_PEDIDOS}/proforma`,
            { precompra: precompraActualizada },
            { headers: { 'Authorization': 'Bearer ' + token } }
        );
        setPrecompra(response.data);
        console.log("Respuesta del post a proforma:", response.data);
        localStorage.setItem('precompra', JSON.stringify(response.data));
    } catch (error) {
        console.error('Error al actualizar precompra:', error);
    }

    if (setActualizando) setActualizando(false);
};
