/* eslint-disable jsx-a11y/anchor-is-valid */
import './Tools.css'

import React from 'react';
import { Link } from 'react-router-dom';
import BannerCatalogo from '../../BannersBody/BannerCatalogo';

const IpTelephony = () => {
    return (
        <>
            {/* Preload the banner image */}
            <link
                rel='preload'
                as='image'
                href={process.env.PUBLIC_URL + 'assets/Banners/banner-catalogo.jpg'}
            />
            <BannerCatalogo />
            <div className='tools-container'>
                <div className='links-container'>
                    <Link to='/'>
                        <a href="#">Home</a>
                    </Link>
                    <br />
                    <a href="#">Configurators</a>
                </div>
                <h1 className='tools-h1'>IP Telephony</h1>
                <div className='ubiquiti-container'>
                    <h2 className='tools-h2'>Grandstream - Utilities</h2>
                    <img
                        src={process.env.PUBLIC_URL + 'imagenes/marcas-grilla/grandstream.gif'}
                        alt='ubi'
                        width={150}
                    />
                </div>
                <ul className='tools-ul'>
                    <li className='tools-li'>
                        Ringtone Generator for Windows.{' '}
                        <a href='https://www.grandstream.com/support/tools'>Link</a>
                    </li>
                    <li className='tools-li'>
                        Ringtone Generator for Linux.{' '}
                        <a href='https://www.grandstream.com/support/tools'>Link</a>
                    </li>
                    <li className='tools-li'>
                        Grandstream IP Discovery Tool.{' '}
                        <a href='https://www.grandstream.com/support/tools'>Link </a>
                    </li>
                </ul>
            </div>
        </>
    );
};

export default IpTelephony;
