import React, { useEffect, useState, useRef } from 'react';
import './CatalogoDigital.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { SpinnerMarcas } from '../helpers/SpinnerMarcas';

const CatalogoDigital = () => {
    const totalPages = 164;
    const imagesPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [loadedImages, setLoadedImages] = useState(imagesPerPage);
    const [isObserving, setIsObserving] = useState(true);
    const startIndex = (currentPage - 1) * imagesPerPage;
    const endIndex = Math.min(startIndex + imagesPerPage, totalPages);
    const [isLoading, setIsLoading] = useState(false);

    const images = [];



    for (let i = startIndex + 1; i <= endIndex; i++) {
        images.push(`${process.env.PUBLIC_URL}/catalogodigital/catalogo_page-${formatNumber(i + 1, 4)}.webp`);
    }

    function formatNumber(number, length) {
        return String(number).padStart(length, '0');
    }

    const inputElements = [];
    let zIndexCSS = '';

    for (let i = startIndex + 1; i <= endIndex; i++) {
        const inputId = `c${i}`;
        inputElements.push(
            <input type="checkbox" id={inputId} key={inputId} />
        );

        const zIndex = endIndex - i + 1;
        zIndexCSS += `#p${i} {
        z-index: ${zIndex};
      }\n`;
        zIndexCSS += `#${inputId}:checked~.flip-book #p${i} {
        transform: rotateY(-180deg);
        z-index: ${i};
      }\n`;
    }

    const loadMoreImages = () => {
        setLoadedImages((prevLoadedImages) => {
            const nextLoad = prevLoadedImages + imagesPerPage;
            return Math.min(nextLoad, totalPages);
        });
    };

    const observer = useRef(null);

    useEffect(() => {
        if (endIndex < totalPages && isObserving) {
            const options = {
                root: null,
                rootMargin: '0px',
                threshold: 0.90,
            };

            const callback = (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting && entry.target) {
                        loadMoreImages();
                        observer.current.unobserve(entry.target);
                    }
                });
            };

            observer.current = new IntersectionObserver(callback, options);
            const target = document.querySelector(`#p${endIndex}`);
            if (target) {
                observer.current.observe(target);
            }

            return () => {
                if (observer.current) {
                    observer.current.disconnect();
                }
            };
        }
    }, [endIndex, isObserving]);


    useEffect(() => {
        if (endIndex === totalPages) {
            setIsObserving(true);
        }
    }, [endIndex, totalPages]);

    const goBack = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
            setIsObserving(false);
            setTimeout(() => {
                const checkboxes = [];
                let startIndexPrevPage = (currentPage - 2) * imagesPerPage;
                for (let i = 8; i >= 0; i--) {
                    const checkbox = document.querySelector(`#c${startIndexPrevPage + i}`);
                    if (checkbox) {
                        checkboxes.push(checkbox);
                    }
                }
                checkboxes.forEach((checkbox) => {
                    checkbox.checked = true;
                });
                checkboxes[0].scrollIntoView();
            }, 0);
        }
    };




    const goForward = () => {
        if (endIndex < totalPages) {
            setIsLoading(true);
            setCurrentPage((prevPage) => prevPage + 1);
            setIsObserving(true);
            setTimeout(() => {
                setIsLoading(false);
            }, 2000);
        }
    };

    const btnDescargarCatalogo = process.env.PUBLIC_URL + "/imagenes/descargarCatalogo.png"
    const catalogo = process.env.PUBLIC_URL + "/pdf/catalogoHD.pdf"

    return (

        isLoading ? <SpinnerMarcas /> : (
            <div className="book-container animate-fade-in">
                <div className="book">
                    {inputElements}
                    <div id="cover">
                        <LazyLoadImage
                            src={`${process.env.PUBLIC_URL}/catalogodigital/catalogo_page-${formatNumber(startIndex + 1, 4)}.webp`}
                            alt="catalogo"
                            threshold={0.5}
                            preload
                        />
                    </div>
                    <div className="flip-book">
                        {images.map((image, index) => (
                            <div key={startIndex + index} className="flip" id={`p${startIndex + index + 1}`}>
                                <div className="back">
                                    <LazyLoadImage
                                        src={image}
                                        alt="catalogo"
                                        threshold={0.5}
                                        preload
                                    />
                                    <label className="back-btn" htmlFor={`c${startIndex + index + 1}`} onClick={() => {
                                        console.log('index en back', index)
                                        if (index === 1) {
                                            goBack()
                                        }
                                    }}>
                                        <span className="icon-container">
                                            <i className="fa fa-arrow-left" aria-hidden="true"></i>
                                        </span>
                                    </label>
                                </div>
                                <div className="front">
                                    <LazyLoadImage
                                        src={image}
                                        alt="catalogo"
                                        threshold={0.5}
                                        preload
                                    />
                                    <label className="next-btn" htmlFor={`c${startIndex + index + 1}`} onClick={() => {
                                        if (index === 9) {
                                            goForward();

                                        } else {
                                            console.log('index en next', index)
                                        }
                                    }}>
                                        <span className="icon-container">
                                            <i className="fa fa-arrow-right" aria-hidden="true"></i>
                                        </span>
                                    </label>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <style>{zIndexCSS}</style>
                <a href={catalogo} download><img className='btnCatalogo' src={btnDescargarCatalogo} alt="" /></a>
            </div>
        )
    );
};

export default CatalogoDigital;
