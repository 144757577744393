import Table from 'react-bootstrap/Table'
import { useTranslation } from 'react-i18next'

export const CartRender = ({ cartLocal }) => {

    const [t, i18next] = useTranslation("global")

    return (
        <>
            <h1 style={{ textAlign: 'center' }}>Resumen de pedido</h1>
            <hr />
            <Table responsive className='tableCart'>
                <thead>
                    <tr>
                        <th>{t("cartRender.cantidad")}</th>
                        <th>{t("cartRender.descripcion")}</th>
                        <th>{t("cartRender.precio")}</th>
                        <th>{t("cartRender.precio-total")}</th>
                    </tr>
                </thead>
                <tbody>
                    {cartLocal.map(item => {
                        return (
                            <tr>
                                <td>{item.contador}</td>
                                <td>{item.Nombre}</td>
                                <td>U$S {item.Precio}</td>
                                <td>U$D {(item.Precio * item.contador).toFixed(2)}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        </>
    )
}