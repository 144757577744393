import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import React, { useContext } from 'react'
import './EstiloPages.css'
import { globalContext } from '../Context/GlobalContext'
import { useTranslation } from 'react-i18next'

export const SelectorRma = () => {
  const { colorTheme, darkMode } = useContext(globalContext)
  const [t, i18next] = useTranslation("global")

  return (
    <div>
      <img src={"https://raw.githubusercontent.com/RamiroSB/imagenes/main/rma-banner.png"} alt="Imagen marcas" className='imagenesBody' loading='lazy'></img>
      <Container fluid className={`contAlt bg-${colorTheme}`}>
        <div className='containerRmaButtons'>
          <Link to="/rma2" className={`${darkMode ? 'butnDarkTheme' : 'butn2'}`}>{t("rma.btn-1")}</Link>
          <Link to="/rma3" className={`${darkMode ? 'butnDarkTheme' : 'butn2'}`}>{t("rma.btn-2")}</Link>
        </div>
      </Container>
    </div>
  )
}
