import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { BsFillPersonFill } from 'react-icons/bs';
import { BiSolidBusiness, BiCube } from 'react-icons/bi';
import { AiOutlineMail, AiOutlinePhone, AiOutlineFieldNumber } from 'react-icons/ai';
import { FaHandsHelping } from 'react-icons/fa';
import { MdDateRange, MdFormatListNumberedRtl } from 'react-icons/md';
import { GiTicket } from 'react-icons/gi';
import { data } from '../../config/config';
import { SpinnerGif } from '../helpers/SpinnerGif';

// Si se quiere modificar la validacion de los campos lo agregamos a los patterns

const formFields = [
    {
        name: 'nombre',
        label: 'formulario.label-1',
        icon: <BsFillPersonFill style={{ color: 'red', fontSize: '24px' }} />,
        type: 'text',
        validation: {
            required: 'Nombre es obligatorio',
            pattern: {
                value: /^[A-Za-z\s]+$/,
                message: 'Nombre solo puede contener letras y espacios'
            }
        }
    },
    {
        name: 'razonSocial',
        label: 'formulario.label-5',
        icon: <BiSolidBusiness style={{ color: 'red', fontSize: '24px' }} />,
        type: 'text',
        validation: {
            required: 'Razón Social es obligatoria',
            pattern: {
                value: /^[A-Za-z0-9\s.]+$/,
                message: 'Razón Social solo puede contener letras, números, espacios y puntos'
            }
        }
    },
    {
        name: 'Cliente',
        label: 'Numero de cliente',
        icon: <BsFillPersonFill style={{ color: 'red', fontSize: '24px' }} />,
        type: 'number',
        validation: {
            required: 'Número de cliente es obligatorio',
            pattern: {
                value: /^[0-9]+$/,
                message: 'Número de cliente solo puede contener números'
            }
        }
    },
    {
        name: 'factura',
        label: 'formulario.factura',
        icon: <AiOutlineFieldNumber style={{ color: 'red', fontSize: '24px' }} />,
        type: 'text',
        validation: {
            required: 'Factura es obligatoria',
            pattern: {
                value: /^[A-Za-z0-9]+$/,
                message: 'Factura solo puede contener letras de la A a la Z y números'
            }
        }
    },
    {
        name: 'telefono',
        label: 'formulario.label-7',
        icon: <AiOutlinePhone style={{ color: 'red', fontSize: '24px' }} />,
        type: 'text',
        validation: {
            required: 'Teléfono es obligatorio',
            pattern: {
                value: /^[0-9]+$/,
                message: 'Teléfono solo puede contener números'
            }
        }
    },
    {
        name: 'email',
        label: 'formulario.label-2',
        icon: <AiOutlineMail style={{ color: 'red', fontSize: '24px' }} />,
        type: 'email',
        validation: {
            required: 'Email es obligatorio',
            pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: 'Correo electrónico inválido'
            }
        }
    },
    {
        name: 'fechaFactura',
        label: 'formulario.fecha-factura',
        icon: <MdDateRange style={{ color: 'red', fontSize: '24px' }} />,
        type: 'date',
        validation: {
            required: 'Fecha de Factura es obligatoria'
        }
    },
    {
        name: 'vendedor',
        label: 'formulario.vendedor',
        icon: <FaHandsHelping style={{ color: 'red', fontSize: '24px' }} />,
        type: 'text',
        validation: {
            required: 'Email vendedor es obligatorio',
            pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: 'Email vendedor debe tener un formato email.'
            }
        }
    },
    {
        name: 'marca',
        label: 'formulario.marca-producto',
        icon: <BiCube style={{ color: 'red', fontSize: '24px' }} />,
        type: 'text',
        validation: {
            required: 'Marca es obligatoria',
            pattern: {
                value: /^[A-Za-z\s]+$/,
                message: 'Marca solo puede contener letras y espacios'
            }
        }
    },
    {
        name: 'Serie',
        label: 'formulario.numero-serie',
        icon: <MdFormatListNumberedRtl style={{ color: 'red', fontSize: '24px' }} />,
        type: 'text',
        validation: {
            required: 'Número de Serie es obligatorio',
            pattern: {
                value: /^[A-Za-z0-9\s]+$/,
                message: 'Número de Serie solo puede contener letras, números y espacios'
            }
        }
    },
    {
        name: 'macId',
        label: 'formulario.mac-id',
        icon: <AiOutlineFieldNumber style={{ color: 'red', fontSize: '24px' }} />,
        type: 'text',
        validation: {
            required: 'MAC ID es obligatorio',
            pattern: {
                value: /^[A-Za-z0-9:/.-]+$/,
                message: 'MAC ID solo puede contener letras (A-Z, a-z), números y caracteres especiales (:, /, - y .)'
            }
        }
    },
    {
        name: 'rmaGrandstream',
        label: 'Ticket aprobado',
        icon: <GiTicket style={{ color: 'red', fontSize: '24px' }} />,
        type: 'text',
        validation: {
            required: 'Ticket aprobado es obligatorio',
            pattern: {
                value: /^[A-Za-z0-9\s]+$/,
                message: 'Ticket aprobado solo puede contener letras, números y espacios'
            }
        }
    },
    {
        name: 'problema',
        label: 'formulario.problema',
        icon: <GiTicket style={{ color: 'red', fontSize: '24px' }} />,
        type: 'textarea',
        validation: {
            required: 'Problema es obligatorio',
            pattern: {
                value: /^[A-Za-z0-9\s]+$/,
                message: 'Este campo solo puede contener letras, números y espacios'
            }
        }
    }
];

const RmaForm = () => {
    const [usuarioValido, setUsuarioValido] = useState(false);
    const apiRma = process.env.REACT_APP_API_CONTACTO;
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const captcha = useRef(null);
    const { t } = useTranslation('global');
    const [loading, setLoading] = useState(false);

    const onSubmit = async (formData) => {
        if (captcha.current.getValue()) {
            setLoading(true); // Activa el estado de carga
            try {
                const response = await fetch(`${apiRma}/rma`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                });

                if (response.ok) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Mensaje enviado correctamente!',
                        text: 'Muchas gracias por su mensaje, nos estaremos comunicando a la brevedad',
                        timer: 3500,
                        showConfirmButton: false,
                    }).then(function () {
                        window.location = "/";
                    });
                } else {
                    throw new Error('Error al enviar el formulario');
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Hubo un problema al enviar el formulario. Por favor, inténtalo de nuevo.',
                });
            } finally {
                setLoading(false); // Desactiva el estado de carga
            }
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Debes realizar la verificación de seguridad!',
            });
        }
    };


    const onChange = () => {
        if (captcha.current.getValue()) {
            setUsuarioValido(true);
        }
    };

    return (
        <>
            {loading ? <SpinnerGif text={'Cargando...'} /> : (
                <Container className="my-5">
                    <Row className="justify-content-center">
                        <Col md={10} lg={8}>
                            <div className="form-box-register p-4 rounded shadow-sm bg-white">
                                <h1 style={{ color: '#0f172a' }} className="text-center mb-4 ">
                                    {t("rma.titulo-form1")} <span style={{ color: '#dc2626' }}>{t("rma.titulo-form2")}</span>
                                </h1>
                                <Form onSubmit={handleSubmit(onSubmit)} className="form-content">

                                    {formFields.map((field) => (
                                        <Form.Group controlId={field.name} key={field.name} className="form-group mb-3">
                                            <Form.Label className=" align-items-center form-label">
                                                {field.icon}
                                                <span className="ms-2">{t(field.label)}</span>
                                            </Form.Label>
                                            {field.type === 'textarea' ? (
                                                <Form.Control
                                                    as="textarea"
                                                    rows={3}
                                                    className="form-control"
                                                    isInvalid={!!errors[field.name]}
                                                    {...register(field.name, field.validation)}
                                                />
                                            ) : (
                                                <Form.Control
                                                    type={field.type}
                                                    className="form-control"
                                                    isInvalid={!!errors[field.name]}
                                                    {...register(field.name, field.validation)}
                                                />
                                            )}
                                            <Form.Control.Feedback type="invalid" className="form-feedback">
                                                {errors[field.name]?.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    ))}
                                    <div style={{
                                        display: 'flex', justifyContent: 'center'
                                    }} className="mb-4 captcha-cont">
                                        <ReCAPTCHA
                                            sitekey={data.sitekey}
                                            ref={captcha}
                                            onChange={onChange}
                                        />
                                    </div>
                                    <div style={{
                                        display: 'flex', justifyContent: 'center'
                                    }}>
                                        <Button
                                            type="submit"
                                            variant="outline-danger"
                                            className={"rounded-pill"}
                                        >
                                            {t("rma.btn-enviar-rma")}
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            )}
        </>

    );
};

export default RmaForm;


