/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import BannerCatalogo from '../../BannersBody/BannerCatalogo';

const Networking = () => {
    return (
        <>
            {/* Preload the banner image */}
            <link
                rel='preload'
                as='image'
                href={process.env.PUBLIC_URL + 'assets/Banners/banner-catalogo.jpg'}
            />
            <BannerCatalogo />
            <div className='tools-container'>
                <div className='links-container'>
                    <Link to='/'>
                        <a href='#'>Home</a>
                    </Link>
                    <br />
                    <a href='#'>Configurators</a>
                </div>
                <h1 className='tools-h1'>Networking, Servers & Storage</h1>
                <div className='ubiquiti-container'>
                    <h2 className='tools-h2'>HP</h2>
                    <img
                        src={process.env.PUBLIC_URL + 'imagenes/marcas-grilla/hp.png'}
                        alt='ubi'
                        width={150}
                    />
                </div>
                <ul className='tools-ul'>
                    <li className='tools-li'>
                        Switch Selector{' '}
                        <a href='http://h17007.www1.hp.com/us/en/networking/products/switches/selector/index.aspx'>
                            Link
                        </a>
                    </li>
                    <li className='tools-li'>
                        UPS Selector{' '}
                        <a href='http://h22193.www2.hp.com/data-storage/index.html?jumpid=va_r11624_us/en/large/tsg/data-storage_ot_ob_ds_pd/data-storage_cc/dt'>
                            Link
                        </a>
                    </li>
                    <li className='tools-li'>
                        Simple Configurator{' '}
                        <a href='http://h22174.www2.hp.com/SimplifiedConfig/Index'>Link</a>
                    </li>
                </ul>
            </div>
        </>
    );
};

export default Networking;
