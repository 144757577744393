import React, { useContext, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { globalContext } from '../Context/GlobalContext';
import { data } from "../../config/config.js"

export const Rma3 = () => {
  const [values, setValues] = useState({
    nombre: "",
    razonSocial: "",
    factura: "",
    fechaFactura: "",
    email: "",
    vendedor: "",
    marca: "Go Pro",
    problema: "",
    telefono: "",
    Serie: "",
    Cliente: "",
    archivo: "",
  });

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;

    let newValue;

    if (type === "number") {
      // Solo permitir números
      newValue = value.replace(/[^0-9]/g, "");
    } else if (
      type === "text" &&
      name !== "macId" &&
      name !== "rmaGrandstream" &&
      name !== "Serie"
    ) {
      // Solo permitir letras
      newValue = value.replace(/[^A-Za-z\s]/g, "");
    } else if (type === "email") {
      // Solo permitir letras
      newValue = value.replace(/[^A-Za-z0-9@._-]/g, "");
    } else if (
      name === "macId" &&
      name === "rmaGrandstream" &&
      name === "Serie"
    ) {
      // Solo permitir letras
      newValue = value.replace(/^[\w.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/g, "");
    } else {
      // Otros tipos de entrada, mantener el valor sin cambios
      newValue = value;
    }

    setValues({
      ...values,
      [name]: newValue,
    });
  };

  const handleKeyPress = (e) => {
    const keyCode = e.which || e.keyCode;
    const isValidKey =
      (keyCode >= 48 && keyCode <= 57) || // Números del 0 al 9
      keyCode === 8 || // Tecla de retroceso (Backspace)
      keyCode === 9; // Tecla Tab

    if (!isValidKey) {
      e.preventDefault();
    }
  };

  const handleKeyPressSpecialCharacters = (e) => {
    const keyCode = e.which || e.keyCode;
    const isValidKey =
      (keyCode >= 48 && keyCode <= 57) || // Números del 0 al 9
      (keyCode >= 65 && keyCode <= 90) || // Letras mayúsculas A-Z
      (keyCode >= 97 && keyCode <= 122) || // Letras minúsculas a-z
      keyCode === 8 || // Tecla de retroceso (Backspace)
      keyCode === 9 || // Tecla Tab
      keyCode === 32; // Tecla espacio

    const forbiddenChars = ["{", "}", "/", "!", "[", "]"];
    const inputValue = String.fromCharCode(keyCode);
    const isCharForbidden = forbiddenChars.includes(inputValue);

    if (!isValidKey || isCharForbidden) {
      e.preventDefault();
    }
  };

  const captcha = useRef(null);

  const handleSubmit = (e) => {
    if (captcha.current.getValue()) {
      Swal.fire({
        icon: "success",
        title: "Mensaje enviado correctamente!",
        text: "Muchas gracias por su mensaje, nos estaremos comunicando a la brevedad",
      }).then(function () {
        window.location = "/";
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Debes realizar la verificacion de seguridad!",
      });
      e.preventDefault();
      console.log("acepta el captcha");
    }
  };

  const { colorTheme } = useContext(globalContext);
  const apiRma = process.env.REACT_APP_API_CONTACTO;

  return (
    <>
      {
        <div className={`container-fluid py-5 bg-${colorTheme}`}>
          <div className={` formulario `}>
            <h2>Formulario de solicitud de RMA Go Pro</h2>
            <hr />
            <form
              className={`text-${!colorTheme === "dark" ? "light" : "dark"
                } formAlignCenter`}
              action={`${apiRma}/rmaGOPRO`}
              method="POST"
              onSubmit={handleSubmit}
              encType="multipart/form-data"
            >
              <p className="formP">Nombre y Apellido</p>
              <input
                style={{ height: "1%" }}
                onChange={handleInputChange}
                name="nombre"
                value={values.nombre}
                className="textForm"
                type="text"
                placeholder="Nombre y Apellido"
                required
              />
              <p className="formP">Razon Social</p>
              <input
                style={{ height: "1%" }}
                onChange={handleInputChange}
                name="razonSocial"
                value={values.razonSocial}
                className="textForm"
                type="text"
                placeholder="Ingrese su Razon Social"
                required
              />
              <p className="formP">Numero de Cliente</p>
              <input
                style={{ height: "1%" }}
                onChange={handleInputChange}
                name="Cliente"
                value={values.Cliente}
                className="textForm"
                type="number"
                onKeyDown={handleKeyPress}
                placeholder="Numero de cliente en Solution Box"
                required
              />
              <p className="formP">Teléfono</p>
              <input
                style={{ height: "1%" }}
                onChange={handleInputChange}
                name="telefono"
                value={values.telefono}
                onKeyDown={handleKeyPress}
                className="textForm"
                type="number"
                placeholder="Ingrese su numero de telefono"
                required
              />
              <p className="formP">Email</p>
              <input
                style={{ height: "1%" }}
                onChange={handleInputChange}
                name="email"
                value={values.email}
                className="textForm"
                type="email"
                placeholder="Ingrese su e-mail"
                autoComplete="off"
                required
              />
              <p className="formP">Factura</p>
              <input
                style={{ height: "1%" }}
                onChange={handleInputChange}
                name="factura"
                value={values.factura}
                className="textForm"
                onKeyDown={handleKeyPressSpecialCharacters}
                type="number"
                placeholder="Ingrese el numero de Factura"
                required
              />
              <p className="formP">Fecha de la Factura</p>
              <input
                style={{ height: "1%" }}
                onChange={handleInputChange}
                name="fechaFactura"
                value={values.fechaFactura}
                className="textForm"
                type="date"
                placeholder="Fecha de la factura"
                autoComplete="off"
                required
              />
              <p className="formP">Vendedor</p>
              <input
                style={{ height: "1%" }}
                onChange={handleInputChange}
                name="vendedor"
                value={values.vendedor}
                className="textForm"
                type="text"
                placeholder="Indique su vendedor"
                autoComplete="off"
                required
              />
              <div className="ocultarCamposForm">
                <p className="formP">Marca del producto</p>
                <input
                  style={{ height: "1%" }}
                  onChange={handleInputChange}
                  name="marca"
                  value={values.marca}
                  className="textForm"
                  type="text"
                  placeholder="Marca del producto"
                  autoComplete="off"
                  required
                />
              </div>
              <p className="formP">Numero de serie</p>
              <input
                style={{ height: "1%" }}
                onChange={handleInputChange}
                onKeyDown={handleKeyPressSpecialCharacters}
                name="Serie"
                value={values.Serie}
                className="textForm"
                type="text"
                placeholder="Ingrese el numero de serie del producto"
                required
              />
              <textarea
                style={{ height: "5%" }}
                onChange={handleInputChange}
                onKeyDown={handleKeyPressSpecialCharacters}
                name="problema"
                value={values.problema}
                className="textForm"
                type="text"
                placeholder="Describa su problema"
                rows="2"
                autoComplete="off"
                required
              />
              <hr />
              <p>Por favor, cargue una imagen con su falla</p>
              <input
                onChange={handleInputChange}
                name="archivo"
                value={values.archivo}
                className="ingreseCV"
                type="file"
                accept=".jpg, .jpeg, .png, .gif, .webp"
                required
              />
              <ReCAPTCHA
                className="mt-3 ml-3"
                ref={captcha}
                sitekey={data.sitekey}
              />
              <button type="submit" className="butn">
                Enviar
              </button>
            </form>
          </div>
        </div>
      }
    </>
  );
};
