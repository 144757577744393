/* eslint-disable jsx-a11y/anchor-is-valid */
import './Tools.css'

import BannerCatalogo from '../../BannersBody/BannerCatalogo'
import { Link } from 'react-router-dom'
import React from 'react'

const Infraestructure = () => {
    return (
        <>
            <BannerCatalogo />
            <div className='tools-container'>
                <div className="links-container">
                    <Link to='/'>
                        <a href="#">Home</a>
                    </Link>
                    <br />
                    <a href="#">Configurators</a>
                </div>
                <h1 className='tools-h1'>Infraestructure</h1>
                <div className="ubiquiti-container">
                    <h2 className='tools-h2'>APC (American Power Conversion)</h2>
                    <img
                        src={process.env.PUBLIC_URL + 'imagenes/marcas-grilla/apc.png'}
                        alt="ubi"
                        width={150}
                    />
                </div>

                <ul className='tools-ul'>
                    <li className='tools-li'>Product Selector <a href="www.apc.com/site/Yourbusiness/index.cfm/resellerspartner/product-selectors/">Link</a></li>
                    <li className='tools-li'>UPS Selector  <a href="https://www.apc.com/us/en/country-selector/?ref_url=/template/not_found.cfm?404;https://www.apc.com:443/tools/ups_selector/">Link</a></li>
                </ul>
                <p>Then CLICK on Home/Office (up to 1500VA) or Server Room (5kva up to 20Kva) or
                    Data Center (up to threephase 10kva up to 200 kw).</p>
                <br />
                <ul className='tools-ul'>
                    <li className='tools-li'> SURGE PROTECTOR Selector and VOLTAGE REGULATION <a href="https://www.apc.com/us/en/country-selector/?ref_url=/template/not_found.cfm?404;https://www.apc.com:443/tools/surge_selector/">LINK</a> then choose what type are you looking for.</li>
                    <li className='tools-li'>PDU Selector  <a href="https://www.apc.com/us/en/country-selector/?ref_url=/tools/selector/pdu/index.cfm?">Link</a></li>
                </ul>
            </div>
        </>
    )
}

export default Infraestructure