import React from "react";
import Productos from "../Productos/Index";

import {
    Container,
    Wrapper
} from './styles';

export const LayOut = () => {
  return (
    <Container>
      <Wrapper>
        <Productos />
      </Wrapper>
    </Container>
  );
};