import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button } from 'react-bootstrap';
import './ProcesarCompraButton.css';
import PagoOptions from '../PagoOptions/PagoOptions';
import { Taxes } from '../../Checkout/Taxes';
import { SpinnerGif } from '../../helpers/SpinnerGif';

const ProcesarCompraButton = ({ onClick, label, isProcessing }) => {
    const [t] = useTranslation('global');
    const [precompra, setPrecompra] = useState(JSON.parse(localStorage.getItem('precompra')));
    const [showModal, setShowModal] = useState(false);

    let taxPesos = [];
    let taxDolares = [];

    function guardar(moneda, array) {
        for (let item in moneda) {
            if (moneda[item] > 0) {
                let nuevo = { [item]: moneda[item].toLocaleString() }
                array.push(nuevo);
            }
        }
    }

    let itemsMapeados = [];

    function mapearItems(items) {
        for (let i = 0; i < items.length; i++) {
            itemsMapeados.push({
                Alias: items[i].Alias,
                Moneda: items[i].Moneda,
                Precio: items[i].Precio,
                Cantidad: items[i].Cantidad,
            })
        }
    }

    guardar(precompra.Subtotal_Pesos, taxPesos);
    guardar(precompra.Subtotal_Dolares, taxDolares);

    mapearItems(precompra.items);

    const handleFileChange = (event) => {
        console.log('Archivo seleccionado:', event.target.files[0]);
    };
    useEffect(() => {
        if (isProcessing) {
            setShowModal(true);
        } else {
            setShowModal(false);
        }
    }, [isProcessing]);




    return (
        <div className="procesar-compra-container">
            <div className="top-section">
                <div className="left-side">
                    <Taxes taxPesos={taxPesos} taxDolares={taxDolares} />
                </div>
                <div className="right-side">
                    <PagoOptions onFileChange={handleFileChange} />
                    {/* <div className="divider"></div> */}
                    <br />
                    <br />
                    <button className="boton-estilizado" onClick={onClick} disabled={isProcessing}>
                        {isProcessing ? "Procesando compra..." : label}
                    </button>
                </div>
            </div>
            <Modal show={showModal} onHide={() => setShowModal(false)} animation={true}>
                <Modal.Header>
                    <Modal.Title>{t('facturacion.ultimo-paso')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>{t('facturacion.procesando-compra')}</h5>
                    <div className="text-center mt-3">
                        <SpinnerGif text={'Procesando...'} />
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default ProcesarCompraButton;
