import React, { useState, useContext } from "react";
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { globalContext } from "../Context/GlobalContext";
import { data } from "../../config/config.js"
import { useTranslation } from "react-i18next";

export const RmaItemFallado = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const { colorTheme } = useContext(globalContext);
  const country = process.env.REACT_APP_COUNTRY;

  let textColorTheme = colorTheme === 'dark' ? 'text-light' : 'text-dark';

  const [t, i18next] = useTranslation("global")

  return (
    <Container fluid className={`p-5 bg-${colorTheme}`}>
      <div className={`form-group py-5 text-${colorTheme === 'dark' ? 'light' : 'dark'}`}
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <h2 style={{textTransform: "uppercase"}}>{t("rma.titulo-condiciones-rma")}</h2>
        <select
          style={{ width: '100%' }}
          className={`form-select`}
          onChange={(e) => setSelectedOption(e.target.value)}
        >
          <option >{t("rma.seleccione-marca")}</option>

          <option value="ADATA">ADATA</option>
          <option value="ARUBA">ARUBA</option>
          <option value="AMD">AMD</option>
          <option value="APC">APC</option>
          <option value="ASUS">ASUS</option>
          <option value="AVER">AVER</option>
          <option value="AXIS">AXIS</option>
          <option value="APPLE">APPLE</option>
          <option value="CAMBIUM">CAMBIUM NETWORKS</option>
          <option value="CISCO_SB">CISCO - CISCO SMALL BUSSINESS</option>
          <option value="CORSAIR">CORSAIR</option>
          <option value="DAHUA">DAHUA TECHNOLOGY</option>
          <option value="DELL">DELL</option>
          <option value="EPSON">EPSON</option>
          <option value="EVGA">EVGA</option>
          <option value="FURUKAWA">FURUKAWA</option>
          <option value="GRANDSTREAM">GRANDSTREAM</option>
          <option value="GOPRO">GOPRO</option>
          <option value="HP">HEWLETT PACKARD </option>
          <option value="HPE">HEWLETT PACKARD ENTERPRISE | ARUBA </option>
          <option value="HUAWEI">HUAWEI</option>
          <option value="HYUNDAI">HYUNDAI</option>
          <option value="KINGSTON">KINGSTON</option>
          <option value="KODAK">KODAK</option>
          <option value="LG">LG</option>
          <option value="LG_MONITORES">LG MONITORES</option>
          <option value="LG_PROYECTORES">LG PROYECTORES</option>
          <option value="LENOVO">LENOVO</option>
          <option value="MIKROTIK">MIKROTIK</option>
          <option value="MSI">MSI</option>
          <option value="OLEX">OLEX</option>
          <option value="PATRIOT">PATRIOT</option>
          <option value="POWERCOLOR">POWERCOLOR</option>
          <option value="PELCO">PELCO</option>
          <option value="QNAP">QNAP</option>
          {country !== 'UY' && (
            <option value="SAMSUNG">SAMSUNG</option>
          )}
          <option value="SEAGATE">SEAGATE</option>
          <option value="TARGUS">TARGUS</option>
          <option value="TPLINK">TP LINK</option>
          <option value="UBIQUITI">UBIQUITI</option>
          <option value="VISIOXIP">VISION X IP</option>
          <option value="WATCHGUARD">WATCHGUARD</option>
          <option value="WESTERN_DIGITAL">WESTERN DIGITAL</option>
          <option value="YEASTAR">YEASTAR</option>
          <option value="OTROS">OTROS</option>
        </select>
      </div>

      {selectedOption === "ADATA" && (
        <div className="form-group">
          <p className={`text-left ${textColorTheme}`}>
            <b>{t("rma.condiciones")} RMA ADATA </b><br />
            {t("rma.garantia")}: 12 {t("rma.meses")}.<br />
            {t("rma.garantia-gestionada-solution-box")}
          </p>
          <br></br>
          <div className="text-center">
            <Link to="/rma2" className="butn3">{t("rma.btn-enviar-rma")}</Link>
          </div>
        </div>
      )}

      {selectedOption === "APC" && (
        <div className="form-group">
          <p className={`text-left ${textColorTheme}`}>
            <b>{t("rma.condiciones")} RMA APC </b><br />
            {t("rma.garantia")}: 24 {t("rma.meses")}.<br />
            {t("rma.apc-contacto")}: <a href="sol@se.com">sol@se.com</a>.<br />
            <b>{t("rma.apc-1")}</b>{t("rma.apc-2")}
          </p>
          <Link to="/rma2" className="butn3">{t("rma.formulario-rma")}</Link>
          <div className="text-center">
          </div>
        </div>
      )}

      {selectedOption === "ASUS" && (
        <div className="form-group">
          <p className={`text-left ${textColorTheme}`}>
            <b>{t("rma.condiciones")} RMA ASUS</b><br />
            {t("rma.garantia")}: 12 {t("rma.meses")}.<br />
            {t("rma.garantia-gestionada-solution-box")}
          </p>
          <div className="text-center">
            <Link to="/rma2" className="butn3">{t("rma.btn-enviar-rma")}</Link>
          </div>
        </div>
      )}
      {selectedOption === "AVER" && (
        <div className="form-group">
          <p className={`text-left ${textColorTheme}`}>
            <b>{t("rma.condiciones")} RMA AVER</b><br />
            {t("rma.garantia")}: 12 {t("rma.meses")}.<br />
            {t("rma.garantia-gestionada-solution-box")}
          </p>
          <div className="text-center">
            <Link to="/rma2" className="butn3">{t("rma.btn-enviar-rma")}</Link>
          </div>
        </div>
      )}
      {selectedOption === "APPLE" && (
        <div className="form-group">
          <p className={`text-left ${textColorTheme}`}>
            <b>{t("rma.condiciones")} RMA APPLE</b><br />
            {t("rma.garantia")}: 12 {t("rma.meses")}.<br />
            <p class="parrafo_marca">{t("rma.apple-1")}: <a rel="noreferrer" href="https://checkcoverage.apple.com/" target="_blank">{t("rma.apple-1")}</a></p>
          </p>
        </div>
      )}
      {selectedOption === "AXIS" && (
        <div className="form-group">
          <p className={`text-left ${textColorTheme}`}>
            <b>{t("rma.condiciones")} RMA AXIS</b><br />
            {t("rma.garantia")}: 12 {t("rma.meses")}.<br />
            <p className="parrafo_marca">{t("rma.axis-1")} <a rel="noreferrer" href="https://www.axis.com/es/login/registration" target="_blank">www.axis.com/es/login/registration</a>.</p>
            <p className="parrafo_marca">{t("rma.axis-2")} <a rel="noreferrer" href="https://www.axis.com/es/support/helpdesk" target="_blank">www.axis.com/es/support/helpdesk</a>.</p>
            <p className="parrafo_marca">{t("rma.axis-3")}</p>
          </p>
          <div className="text-center">
            <Link to="/rma2" className="butn3">{t("rma.btn-enviar-rma")}</Link>
          </div>
        </div>
      )}

      {selectedOption === "HP" && (
        <div className="form-group">
          <p className={`text-left ${textColorTheme}`}>
            <b>{t("rma.condiciones")} RMA HEWLETT PACKARD - Impresoras y Cómputos</b><br />
            {t("rma.garantia")} 12 {t("rma.meses")}. <br />
            {t("rma.hp-1")} <br />
            {t("rma.hp-2")} <br />
            <div className="text-center">
              <Link to="/rma2" className="butn3">{t("rma.btn-enviar-rma")}</Link>
            </div>
          </p>
        </div>
      )}
      {selectedOption === "HPE" && (
        <div className="form-group">
          <p className={`text-left ${textColorTheme}`}>
            <b>{t("rma.condiciones")} RMA HEWLETT PACKARD - Enterprise</b><br />
            <p className="parrafo_marca">Garantía Aruba: <a rel="noreferrer" href="https://www.arubanetworks.com/es/servicios-de-apoyo/garantias-de-productos/" target="_blank">https://www.arubanetworks.com/es/servicios-de-apoyo/garantias-de-productos/</a></p>
            {t("rma.hpe-1")}<br /><br />
            <p className="parrafo_marca"> Support: <a rel="noreferrer" href="https://support.hpe.com/connect/s/createcase" target="_blank">https://support.hpe.com/connect/s/createcase</a> </p>
            <p class="parrafo_marca">Instant On: <a rel="noreferrer" href="https://www.arubainstanton.com/contact-support/" target="_blank">arubainstanton.com/contact-support/</a> </p>
            <div className="text-center"><a href="https://support.hpe.com/connect/s/?language=es" className="butn3">{t("rma.click")}</a></div>
          </p>
        </div>
      )}

      {selectedOption === "CAMBIUM" && (
        <div className="form-group">
          <p className={`text-left ${textColorTheme}`}>
            <b>{t("rma.condiciones")} RMA CAMBIUM NETWORKS</b><br />
            {t("rma.garantia")} 12 {t("rma.meses")}. <br />
            {t("rma.cambium-1")}<br /><br />
            <a href="https://www.cambiumnetworks.com/support/contact-support/" className="butn3">{t("rma.click")}</a>
          </p>
        </div>
      )}


      {selectedOption === "CISCO_SB" && (
        <div className="form-group">
          <p className={`text-left ${textColorTheme}`}>
            <b>{t("rma.condiciones")} RMA CISCO - CISCO SMALL BUSSINESS </b><br />
            {t("rma.garantia")}: 12 {t("rma.meses")}.<br />
            {t("rma.garantia-gestionada-solution-box")}
            {t("rma.cisco-1")}
          </p>
          <div className="text-center">
            <Link to="/rma2" className="butn3">{t("rma.btn-enviar-rma")}</Link>
          </div>
        </div>
      )}

      {selectedOption === "CORSAIR" && (
        <div className="form-group">
          <p className={`text-left ${textColorTheme}`}>
            <b>{t("rma.condiciones")} RMA CORSAIR </b><br />
            {t("rma.garantia")}: 12 {t("rma.meses")}.<br />
            {t("rma.garantia-gestionada-solution-box")}
          </p>
          <div className="text-center">
            <Link to="/rma2" className="butn3">{t("rma.btn-enviar-rma")}</Link>
          </div>
        </div>
      )}

      {selectedOption === "DAHUA" && (
        <div className="form-group">
          <p className={`text-left ${textColorTheme}`}>
            <b>{t("rma.condiciones")} RMA DAHUA </b><br />
            {t("rma.garantia")}: 12 {t("rma.meses")}.<br />
            {t("rma.dahua-1")}
            <br></br>{t("rma.dahua-2")}<br />
            {t("rma.soporte")} <b><a href="supportoverseas@dahuatech.com">supportoverseas@dahuatech.com</a> / +52 5593372150 </b><br />
            {t("rma.dahua-3")} <b>Solution Box</b>.
          </p>
          <div className="text-center">
            <Link to="/rma2" className="butn3">{t("rma.btn-enviar-rma")}</Link>
          </div>
        </div>
      )}
      {selectedOption === "DELL" && (
        <div className="form-group">
          <p className={`text-left ${textColorTheme}`}>
            <b>{t("rma.condiciones")} RMA DELL </b><br />
            {t("rma.garantia")}: 12 {t("rma.meses")}.<br />
            <p className="parrafo_marca">{t("rma.dell-call-center")}: 0004135982521</p>
            <br />{t("rma.dell-1")}<br />
            <p className="parrafo_marca">{t("rma.dell-2")} Whatsapp: +919108135362 (Solo para Enterprise)
              {t("formulario.horario")}:
              0800-1700 (UTC/GMT-5)|Lun-vie</p>
          </p>
          <div className="text-center">
            <Link to="/rma2" className="butn3">{t("rma.btn-enviar-rma")}</Link>
          </div>
        </div>
      )}
      {selectedOption === "EPSON" && (
        <div className="form-group">
          <p className={`text-left ${textColorTheme}`}>
            <b>{t("rma.condiciones")} RMA EPSON </b><br />
            {t("rma.epson-1")} <br />
            {t("rma.epson-2")} <br />
            {t("rma.epson-3")} <br />
            {t("rma.epson-4")} <br />
            {t("rma.epson-5")}: 00040-521-0067
          </p>
          <div className="text-center">
            <Link to="/rma2" className="butn3">{t("rma.btn-enviar-rma")}</Link>
          </div>
        </div>
      )}
      {selectedOption === "EVGA" && (
        <div className="form-group">
          <p className={`text-left ${textColorTheme}`}>
            <b>{t("rma.condiciones")} RMA EVGA </b><br />
            {t("rma.garantia")} 1 año con Solution Box.
          </p>
          <div className="text-center">
            <Link to="/rma2" className="butn3">{t("rma.btn-enviar-rma")}</Link>
          </div>
        </div>
      )}
      {selectedOption === "FURUKAWA" && (
        <div className="form-group">
          <p className={`text-left ${textColorTheme}`}>
            <b>{t("rma.condiciones")} RMA FURUKAWA </b><br />
            {t("rma.garantia")}: 12 {t("rma.meses")}.<br />
            {t("rma.garantia-gestionada-solution-box")}.
          </p>
          <div className="text-center">
            <Link to="/rma2" className="butn3">{t("rma.btn-enviar-rma")}</Link>
          </div>
        </div>
      )}

      {selectedOption === "TARGUS" && (
        <div className="form-group">
          <p className={`text-left ${textColorTheme}`}>
            <b>{t("rma.condiciones")} RMA TARGUS </b><br />
            {t("rma.garantia")}: 12 {t("rma.meses")}.<br />
            {t("rma.garantia-gestionada-solution-box")}
          </p>
          <div className="text-center">
            <Link to="/rma2" className="butn3">{t("rma.btn-enviar-rma")}</Link>
          </div>
        </div>
      )}

      {selectedOption === "TPLINK" && (
        <div className="form-group">
          <p className={`text-left ${textColorTheme}`}>
            <b>{t("rma.condiciones")} RMA TP LINK </b><br />
            {t("rma.garantia")}: 3 años.<br />
            <p class="parrafo_marca">{t("rma.tp-link-1")} <a rel="noreferrer" href="https://www.tp-link.com/latam/support">{t("rma.tp-link-2")}</a></p>
          </p>
          <div className="text-center">
            <Link to="/rma2" className="butn3">{t("rma.btn-enviar-rma")}</Link>
          </div>
        </div>
      )}


      {selectedOption === "GRANDSTREAM" && (
        <div className="form-group">
          <p className={`text-left ${textColorTheme}`}>
            <b>{t("rma.condiciones")} RMA GRANDSTREAM</b><br />
            {t("rma.garantia")}: 12 {t("rma.meses")}.<br />
            {t("rma.grandstream-1")} <a href="https://helpdesk.grandstream.com ">{t("rma.click")}</a> <br />
            {t("rma.grandstream-2")} <a href={`mailto:${data.mail_rma}`}>{data.mail_rma}</a><br />
            {t("rma.grandstream-3")}
          </p>
          <div className="text-center">
            <Link to="/rma2" className="butn3">{t("rma.btn-enviar-rma")}</Link>
          </div>
        </div>
      )}
      {selectedOption === "GOPRO" && (
        <div className="form-group">
          <p className={`text-left ${textColorTheme}`}>
            {/* <img src="https://www.solutionbox.com.do/images/carrusel/grandstream.png" alt="grandstream"/><br/> */}
            <b>{t("rma.condiciones")} RMA GOPRO</b><br />
            {t("rma.garantia")}: 12 {t("rma.meses")}.<br />
            {t("rma.garantia-gestionada-solution-box")}.
          </p>
          <div className="text-center">
            <Link to="/rma-gopro" className="butn3">{t("rma.formulario-rma")}</Link>
          </div>
        </div>
      )}

      {selectedOption === "UBIQUITI" && (
        <div className="form-group">
          <p className={`text-left ${textColorTheme}`}>
            <b>{t("rma.condiciones")} RMA UBIQUITI</b><br />
            {t("rma.garantia")} 12 {t("rma.meses")}.<br />
            {t("rma.garantia-gestionada-solution-box")}<br />
          </p>
          <div className="text-center">
            <Link to="/rma2" className="butn3">{t("rma.btn-enviar-rma")}</Link>
          </div>
        </div>
      )}

      {selectedOption === "HUAWEI" && (
        <div className="form-group">
          <p className={`text-left ${textColorTheme}`}>
            <b>{t("rma.condiciones")} RMA HUAWEI</b><br />
            {t("rma.garantia")}: 12 {t("rma.meses")}.<br />
            {t("rma.garantia-gestionada-solution-box")}
          </p>
          <div className="text-center">
            <Link to="/rma2" className="butn3">{t("rma.btn-enviar-rma")}</Link>
          </div>
        </div>
      )}
      {selectedOption === "KINGSTON" && (
        <div className="form-group">
          <p className={`text-left ${textColorTheme}`}>
            <b>{t("rma.condiciones")} RMA KINGSTON</b><br />
            Garantía Memorias: 5 años | SSD: 3 años<br />
            {t("rma.garantia-gestionada-solution-box")}
          </p>
          <div className="text-center">
            <Link to="/rma2" className="butn3">{t("rma.btn-enviar-rma")}</Link>
          </div>
        </div>
      )}
      {selectedOption === "KODAK" && (
        <div className="form-group">
          <p className={`text-left ${textColorTheme}`}>
            <b>{t("rma.condiciones")} RMA KODAK</b><br />
            {t("rma.garantia")} 12 {t("rma.meses")}.<br />
            {t("rma.kodak")}:<br />
            ARNALDO C. CASTRO S.A.<br />
            https://arnaldocastro.com.uy<br />
            {t("navbar.contacto")}: info@arnaldocastro.com.uy<br />
            {t("formulario.label-3")}: (598) 2902 7000<br />
            {t("formulario.direccion")}: Julio Herrera y Obes 1626, Montevideo, Uruguay.<br />
          </p>
          <div className="text-center">
            <Link to="/rma2" className="butn3">{t("rma.btn-enviar-rma")}</Link>
          </div>
        </div>
      )}

      {selectedOption === "LENOVO" && (
        <div className="form-group">
          <p className={`text-left ${textColorTheme}`}>
            <b>{t("rma.condiciones")} RMA LENOVO</b><br />
            {t("rma.garantia")}: de 12 a 36 {t("rma.meses")}, {t("rma.lenovo-1")}<br />
            DOA SOLUTION BOX 10 {t("rma.lenovo-2")}<br />
            {t("rma.lenovo-3")}<br />
            {t("rma.soporte")}: soportegaran@lenovo.com<br />
          </p>
          <div className="text-center">
            <Link to="/rma2" className="butn3">{t("rma.lenovo-click")}</Link>
          </div>
        </div>
      )}
      {selectedOption === "LG" && (
        <div className="form-group">
          <p className={`text-left ${textColorTheme}`}>
            <b>{t("rma.condiciones")} LG</b><br />
            Cliente final a Service IT.
            <a rel="noreferrer" className="btn btn-mini" target="_blank" href="http://www.serviceit.com.uy"><strong>SERVICE IT</strong></a><br />
            {t("navbar.contacto")}: <a href="mailto:serviceit@serviceit.com.uy">serviceit@serviceit.com.uy</a> / <a href="mailto:gdeleon@serviceit.com.uy">gdeleon@serviceit.com.uy</a><br />
            {t("formulario.direccion")}: Canelones 2179 entre Requena y Paullier, L. a V. de 9 a 13 y 14 a 18 hs.<br />
          </p>
          <div className="text-center">
            <Link to="/rma2" className="butn3">{t("rma.btn-enviar-rma")}</Link>
          </div>
        </div>
      )}
      {selectedOption === "LG_MONITORES" && (
        <div className="form-group">
          <p className={`text-left ${textColorTheme}`}>
            <b>{t("rma.condiciones")} LG Monitores</b><br />
            Cliente final a Service IT.
            <a rel="noreferrer" className="btn btn-mini" target="_blank" href="http://www.serviceit.com.uy"><strong>SERVICE IT</strong></a><br />
            {t("navbar.contacto")}: <a href="mailto:serviceit@serviceit.com.uy">serviceit@serviceit.com.uy</a> / <a href="mailto:gdeleon@serviceit.com.uy">gdeleon@serviceit.com.uy</a><br />
            {t("formulario.direccion")}: Canelones 2179 entre Requena y Paullier, L. a V. de 9 a 13 y 14 a 18 hs.<br />
          </p>
          <div className="text-center">
            <Link to="/rma2" className="butn3">{t("rma.btn-enviar-rma")}</Link>
          </div>
        </div>
      )}
      {selectedOption === "LG_PROYECTORES" && (
        <div className="form-group">
          <p className={`text-left ${textColorTheme}`}>
            <b>{t("rma.condiciones")} LG Proyectores</b><br />
            {t("rma.garantia")} 12 {t("rma.meses")}. <br />
            {t("rma.lg-proyectores-1")} 12 {t("rma.meses")}. <br />
            {t("rma.lg-proyectores-2")} 6 {t("rma.meses")}. <br />
          </p>
          <div className="text-center">
            <Link to="/rma2" className="butn3">{t("rma.btn-enviar-rma")}</Link>
          </div>
        </div>
      )}

      {selectedOption === "MIKROTIK" && (
        <div className="form-group">
          <p className={`text-left ${textColorTheme}`}>
            <b>{t("rma.condiciones")} RMA MIKROTIK</b><br />
            {t("rma.garantia")}: 12 {t("rma.meses")}.<br />
            {t("rma.garantia-gestionada-solution-box")}
          </p>
          <div className="text-center">
            <Link to="/rma2" className="butn3">{t("rma.btn-enviar-rma")}</Link>
          </div>
        </div>
      )}
      {selectedOption === "MSI" && (
        <div className="form-group">
          <p className={`text-left ${textColorTheme}`}>
            <b>{t("rma.condiciones")} RMA MSI</b><br />
            {t("rma.garantia")}: 12 {t("rma.meses")}.<br />
            {t("rma.garantia-gestionada-solution-box")}
          </p>
          <div className="text-center">
            <Link to="/rma2" className="butn3">{t("rma.btn-enviar-rma")}</Link>
          </div>
        </div>
      )}
      {selectedOption === "OLEX" && (
        <div className="form-group">
          <p className={`text-left ${textColorTheme}`}>
            <b>{t("rma.condiciones")} RMA OLEX</b><br />
            {t("rma.olex-1")}<br />
            <p class="parrafo_marca">Página web: <a rel="noreferrer" href="https://olexla.com" target="_blank">https://olexla.com/</a></p>
          </p>
          <div className="text-center">
            <Link to="/rma2" className="butn3">{t("rma.btn-enviar-rma")}</Link>
          </div>
        </div>
      )}
      {selectedOption === "PATRIOT" && (
        <div className="form-group">
          <p className={`text-left ${textColorTheme}`}>
            <b>Garantía para SSD: 3 años / Garantía para accesorios: 1 año. </b><br />
            {t("rma.garantia-gestionada-solution-box")} <br />
          </p>
          <div className="text-center">
            <Link to="/rma2" className="butn3">{t("rma.btn-enviar-rma")}</Link>
          </div>
        </div>
      )}
      {selectedOption === "POWERCOLOR" && (
        <div className="form-group">
          <p className={`text-left ${textColorTheme}`}>
            <b>{t("rma.condiciones")} RMA POWERCOLOR</b><br />
            {t("rma.garantia")} 12 {t("rma.meses")}. <br />
            {t("rma.garantia-gestionada-solution-box")}
          </p>
          <div className="text-center">
            <Link to="/rma2" className="butn3">{t("rma.btn-enviar-rma")}</Link>
          </div>
        </div>
      )}
      {selectedOption === "PELCO" && (
        <div className="form-group">
          <p className={`text-left ${textColorTheme}`}>
            <b>{t("rma.condiciones")} RMA PELCO</b><br />
            {t("rma.garantia")} 12 {t("rma.meses")}. <br />
            {t("rma.pelco")} Tel. 000 4054 069.
          </p>
          <div className="text-center">
            <Link to="/rma2" className="butn3">{t("rma.btn-enviar-rma")}</Link>
          </div>
        </div>
      )}

      {selectedOption === "QNAP" && (
        <div className="form-group">
          <p className={`text-left ${textColorTheme}`}>
            <b>{t("rma.condiciones")} RMA QNAP</b><br />
            {t("rma.garantia")}: 12 {t("rma.meses")}.<br />
            {t("rma.garantia-gestionada-solution-box")}
          </p>
          <div className="text-center">
            <Link to="/rma2" className="butn3">{t("rma.btn-enviar-rma")}</Link>
          </div>
        </div>
      )}
      {selectedOption === "SAMSUNG" && country !== "UY" && (
        <div className="form-group">
          <p className={`text-left ${textColorTheme}`}>
            <b>{t("rma.condiciones")} RMA Samsung</b><br />
            {t("rma.garantia")}: 12 {t("rma.meses")}.<br />
            {t("rma.samsung-1")}
            <p class="parrafo_marca">{t("rma.samsung-2")}:  <a rel="noreferrer" target="_blank" href="https://www.samsung.com/latin/support/your-service/install-request/">Install Request (samsung.com)</a> {t("rma.samsung-3")}
              {t("rma.samsung-4")}</p>
          </p>
          <br></br>
          <div className="text-center">
            <Link to="/rma2" className="butn3">{t("rma.samsung-4")}</Link>
          </div>
        </div>
      )}
      {selectedOption === "SEAGATE" && (
        <div className="form-group">
          <p className={`text-left ${textColorTheme}`}>
            <b>{t("rma.condiciones")} RMA SEAGATE</b><br />
            {t("rma.garantia")}: 12 {t("rma.meses")}.<br />
            <p class="parrafo_marca">{t("rma.seagate-1")} <a rel="noreferrer" target="_blank" href="https://www.seagate.com/support/warranty-and-replacements/">LINK</a></p>
          </p>
          <div className="text-center">
            <Link to="/rma2" className="butn3">{t("rma.btn-enviar-rma")}</Link>
          </div>
        </div>
      )}
      {selectedOption === "VISIOXIP" && (
        <div className="form-group">
          <p className={`text-left ${textColorTheme}`}>
            <b>{t("rma.condiciones")} RMA VISIOXIP</b><br />
            {t("rma.garantia")}: 12 {t("rma.meses")}.<br />
            {t("rma.garantia-gestionada-solution-box")}
          </p>
          <div className="text-center">
            <Link to="/rma2" className="butn3">{t("rma.btn-enviar-rma")}</Link>
          </div>
        </div>
      )}
      {selectedOption === "WATCHGUARD" && (
        <div className="form-group">
          <p className={`text-left ${textColorTheme}`}>
            <b>{t("rma.condiciones")} RMA WATCHGUARD</b><br />
            {t("rma.garantia")}: 12 {t("rma.meses")}.<br />
            {t("rma.garantia-gestionada-solution-box")}
            <p class="parrafo_marca">{t("rma.watchguard-1")} (<a rel="noreferrer" target="_blank" href="http://www.watchguard.com/wgrd-support-services/overview">www.watchguard.com</a>), {t("rma.watchguard-2")}</p>
          </p>
          <div className="text-center">
            <Link to="/rma2" className="butn3">{t("rma.btn-enviar-rma")}</Link>
          </div>
        </div>
      )}
      {selectedOption === "WESTERN_DIGITAL" && (
        <div className="form-group">
          <p className={`text-left ${textColorTheme}`}>
            <b>{t("rma.condiciones")} RMA WESTERN_DIGITAL</b><br />
            {t("rma.garantia")}: 12 {t("rma.meses")}.<br />
            {t("rma.garantia-gestionada-solution-box")} <br />
          </p>
          <div className="text-center">
            <Link to="/rma2" className="butn3">{t("rma.btn-enviar-rma")}</Link>
          </div>
        </div>
      )}

      {selectedOption === "YEASTAR" && (
        <div className="form-group">
          <p className={`text-left ${textColorTheme}`}>
            <b>{t("rma.condiciones")} RMA YEASTAR</b><br />
            {t("rma.yeastar-1")}<br />
            {t("rma.yeastar-2")}<br />
            MAC ID<br />
            {t("formulario.problema")}<br />
            {t("rma.yeastar-3")}<br />
          </p>
        </div>
      )}

      {selectedOption === "ARUBA" && (
        <div className="form-group">
          <p className={`text-left ${textColorTheme}`}>
            <b>{t("rma.condiciones")} RMA ARUBA</b><br />
            {t("rma.garantia")}: 12 {t("rma.meses")}.<br />
            {t("rma.aruba-1")}:<br />
            <a rel="noreferrer" target="_blank" href="https://www.arubanetworks.com/support-services/contact-support"> Contact Support | HPE Aruba Networking (arubanetworks.com)</a>
          </p>
        </div>
      )}

      {selectedOption === "HYUNDAI" && (
        <div className="form-group">
          <p className={`text-left ${textColorTheme}`}>
            <b>{t("rma.condiciones")} RMA HYUNDAI</b><br />
            {t("rma.garantia")}: 12 {t("rma.meses")}.<br />
            {t("rma.garantia-gestionada-solution-box")}
          </p>
          <div className="text-center">
            <Link to="/rma2" className="butn3">{t("rma.btn-enviar-rma")}</Link>
          </div>
        </div>
      )}

      {selectedOption === "AMD" && (
        <div className="form-group mt-5">
          <p className="text-left">
            <b>{t("rma.condiciones")} RMA AMD</b><br />
            {t("rma.amd-1")} 12 {t("rma.meses")} {t("rma.amd-2")}<br />
            {t("rma.amd-3")} <br /><br />
            <a href="https://www.amd.com/en/support/kb/warranty-information/rma-form" className="butn3">Click here</a>
          </p>
        </div>
      )}

      {selectedOption === "OTROS" && (
        <div className="form-group">
          <p className={`text-left ${textColorTheme}`}>
            <b>{t("rma.condiciones")} RMA para OTROS</b><br />
            {t("rma.otros")}<br /></p>
          <div className="text-center">
            <Link to="/rma2" className="butn3">{t("rma.btn-enviar-rma")}.</Link>
          </div>
        </div>
      )}

    </Container>

  )
}