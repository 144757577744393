import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;

  @media screen and (max-width:600px) {
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  }
`;
export const Panel = styled.div`
  background-color: var(--white);
  box-shadow: var(--panel);
  display: grid;
  grid-template-columns: 67fr 33fr;

  @media screen and (max-width:600px) {
    background-color: var(--white);
  box-shadow: var(--panel);
  display: flex;
  justify-content: center;
  flex-direction: column;
  }
`;
export const Column = styled.div`
  
`;
export const Galeria = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  //height: 530px;
  > img {
    height: 75%;
  }
`;