import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import './FormularioStyle.css';

export const ErrorForm =()=>{

    const navigate = useNavigate()

    return(
        <div className='divErrorForm'>
            <h1>Hubo un error al enviar el mail</h1>
            <h4>Intente cargar sus datos nuevamente:</h4>
            <div>
                <Button 
                    variant='secondary'
                    onClick={()=>navigate('/trabajaconnosotros')}
                >
                    Volver al formulario
                </Button>
            </div>
            <h4>Si el problema persiste, por favor contactese con la empresa</h4>
        </div>
    )
}