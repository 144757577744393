import React from 'react'
import ConfiguradorHpe from './ConfiguradorHpe'
import { BannerHpe } from '../BannersBody/BannerHpe'


export const Iquote = () => {
  return (
    <>
      <BannerHpe />
      <ConfiguradorHpe />
    </>
  )
}
