import { Button } from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import './helpers.css'

export const Unauthorized =()=>{

    const navigate = useNavigate()
    const irALogin = (e) =>{
        e.preventDefault()
        navigate('/login')
    }

    return(
        <div className='divAuth'>
            <h1>Ud. no está autorizado</h1>
            <span>🚫</span>
            <h3>Por favor, inicie sesión para ver esta página</h3>
            <Button variant='secondary' size='lg' onClick={irALogin}>Ir a Login</Button>
        </div>
    )
}