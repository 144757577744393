import { useContext, useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import axios from "axios";
import { Unauthorized } from '../helpers/Unauthorized';
import { DatosPersonales } from './DatosPersonales';
import { ChangePassword } from '../Login/ChangePassword';
import { Historial } from './Historial';
import Cart from '../Carrito/Cart';
import './Profile.css';
import { BadGateway } from '../helpers/BadGateway';
import { authContext } from '../Context/AuthContext';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const apiPedidos = process.env.REACT_APP_API_PEDIDOS;

export const Profile = () => {
    const { token, user } = useContext(authContext);
    const [compras, setCompras] = useState();
    const navigate = useNavigate()

    console.log(user, "user")

    useEffect(() => {
        if (!user) return false;

        axios
            .get(`${apiPedidos}/ordenes/cliente/${user.Cliente}?Limit=${10}&Offset=${0}`, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((res) => {
                if (res.status === 200) {
                    setCompras(res.data.pedidos);
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response && err.response.status === 500) {
                    toast.error('No se puede ver esta sección en estos momentos');
                    navigate('/');
                } else if (err.response && err.response.status === 404) {
                    toast.error('No se puede ver la informacion en estos momentos');
                } else if (err.response && err.response.status === 401) {
                    toast.error('Aun no hay compras a su nombre.');
                } else {
                    console.log(err);
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    if (!token) return <Unauthorized />;

    // if (!compras) return <BadGateway />;

    return (
        <>
            <div className="divPerfil">
                <h1>Perfil de control de {user.Nombre}</h1>
                <h5>Desde esta pantalla puede administrar su cuenta y ver información sobre sus pedidos</h5>
            </div>
            <Tabs
                defaultActiveKey="historial"
                id="fill-tab-example"
                className="mb-3"
                fill
            >
                <Tab eventKey="datos-personales" title="Datos personales">
                    <DatosPersonales user={user} />
                </Tab>
                <Tab eventKey="carrito" title="Carrito">
                    <Cart />
                </Tab>
                <Tab eventKey="historial" title="Historial de compras">
                    <Historial compras={compras} />
                </Tab>
                <Tab eventKey="psw" title="Cambiar contraseña">
                    <ChangePassword />
                </Tab>
            </Tabs>
        </>
    );
};
